import { BaseQueryApi, BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from "@reduxjs/toolkit/query/react";
import { getBody, getPremNotesBody } from "../utils/old_v1/helpers";
import { clearState, setUserObject } from "../app/slices/user/userSlice";
import { Mutex } from "async-mutex";
import { formatBaseApiAgencyUUIDUrl } from "../utils/url";
import { decodeToken, getAccessToken, getRefreshToken, setAccessToken, tokenIsValid } from "../utils/token";
import { Cookies } from "react-cookie";

export const INVALID_CREDENTIALS = "Access Denied";

const baseUrl = window?.__RUNTIME_CONFIG__?.REACT_APP_DJANGO_BASE_URL;

const DjangoBaseQuery = fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
        const cookies = new Cookies(null, { path: "/" });
        const accessToken = cookies.get("accessToken");
        if (accessToken && accessToken !== "") {
            headers.set("Authorization", `Bearer ${accessToken}`);
        }
        headers.set("Content-Type", "application/json");
        return headers;
    },
});

const mutex = new Mutex();

const signOut = (api: BaseQueryApi, pageToNav: string) => {
    api.dispatch(clearState());
    const cookies = new Cookies(null, { path: "/" });
    cookies.remove("accessToken", { path: "/" });
    cookies.remove("refreshToken", { path: "/" });
    const searchParams = new URLSearchParams(
        pageToNav
            ? {
                  redirect: pageToNav,
              }
            : {}
    );
    window.location.assign(`/auth/sign-in?${searchParams.toString()}`);
    // abort the request to not send unauthorized requests to the server
    api.abort("User access and refresh tokens have expired");
};

// Runs the api calls inside of login which requires a bearer token (access token).
// Verifies the expiration dates of the access and refresh tokens and will update the access token and user object
// if the access token has expired but the refresh token is still valid.  If both access and refresh tokens are expired
// the user is redirected to sign in with brute force using window.location.assign and redux is cleared.
const ReauthBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api: BaseQueryApi, extraOptions) => {
    const accessToken = getAccessToken();
    const refreshToken = getRefreshToken();
    const requestedUrl = `${window.location.pathname}${window.location.search}`;

    await mutex.waitForUnlock();

    // use a locking mechanism to ensure the refresh is only run by the initial request
    if (!mutex.isLocked()) {
        // acquire a lock to pause other api calls
        const release = await mutex.acquire();
        try {
            // check token validity
            if (!tokenIsValid(accessToken) && !tokenIsValid(refreshToken)) {
                // both tokens are expired
                await mutex.cancel();
                signOut(api, requestedUrl);
            } else if (!tokenIsValid(accessToken)) {
                // access token is expired, but the refresh is still good, get new access token
                const refreshTokenResult = await DjangoBaseQuery(
                    { url: "v2/auth/token/refresh", method: "POST", body: { refresh: getRefreshToken() } },
                    api,
                    extraOptions
                );
                if (refreshTokenResult.error) {
                    // if we couldn't get a new access token, sign user out
                    signOut(api, requestedUrl);
                } else if (refreshTokenResult.data) {
                    // update token in cookie
                    const newAccessToken = (refreshTokenResult.data as any).access;
                    const decodedNewAccessToken = decodeToken(newAccessToken);

                    if (decodedNewAccessToken) {
                        setAccessToken(newAccessToken);
                    } else {
                        // something went wrong decoding the new access token. Go to sign-in
                        signOut(api, requestedUrl);
                    }

                    // get a new user object for redux
                    const refreshedUser = await DjangoBaseQuery({ url: "/v2/user", method: "GET" }, api, extraOptions);
                    if (refreshedUser.error) {
                        // something went wrong getting an updated user object from the server. Go to sign-in
                        signOut(api, requestedUrl);
                    } else {
                        // Update the user object in redux
                        const userObject = (refreshedUser.data as any).results;
                        api.dispatch(setUserObject(userObject));
                    }
                }
            }
        } finally {
            // always release the lock
            release();
        }
        return DjangoBaseQuery(args, api, extraOptions);
    } else {
        // another request has the lock, wait for it to release
        await mutex.waitForUnlock();
        // after waiting for the first request to unlock check if the tokens are still valid
        // the initial request will have either updated a token that was expired or removed them and redirected the user to the login page
        // at this point we abort the remaining locked requests to prevent unauthorized requests to the server
        if (!tokenIsValid(accessToken) && !tokenIsValid(refreshToken)) {
            api.abort("User access and refresh tokens have expired");
        }
        return DjangoBaseQuery(args, api, extraOptions);
    }
};

export const VALIDATE_CODE_ROUTE = "/v2/auth/token";
export const LOGIN_ROUTE = "/v2/auth/token/mfa";
export const REQUEST_PASSWORD_RESET_ROUTE = "/v2/auth/password-reset/request";
export const VALIDATE_CHANGE_PASSWORD_TOKEN_ROUTE = "/v2/auth/password-reset/validate";
export const SUBMIT_CHANGE_PASSWORD_ROUTE = "/v2/auth/password-reset/set";
export const SEND_CODE_ROUTE = "/v2/auth/mfa/sms";
export const SUBMIT_CODE_ROUTE = "/v2/auth/token";

export const ACCOUNT_SETUP_VALIDATE_TOKEN = "/v1/user/account-setup/validate-token";
export const ACCOUNT_SETUP_VALIDATE_PASSWORD = "/v1/user/account-setup/validate-password";
export const ACCOUNT_SETUP_SETUP_SMS_MFA = "/v1/user/account-setup/setup-sms-mfa";
export const ACCOUNT_SETUP_CONFIGURE_ACCOUNT = "/v1/user/account-setup/configure-account";

export const SAML_PROCESSING_ROUTE = "/v1/access/get_token/";

/**
 * Provides the api calls for the FE to communicate with the server.  Each api endpoint is defined as either a query
 * or mutation using the Redux Toolkit Query library (RTKQ).  RTKQ provides a baseQuery function that provides
 * the base fetch logic for each of the endpoints.  Since the server requires a bearer token (the jwt access token),
 * a ReauthBaseQuery function wraps the RTKQ fetchBaseQuery utility function to set the token properly.
 *
 * tagTypes are supplied to manage the cached data that RTKQ maintains for each request.  Cached values are used for
 * queries if the request payload hasn't changed or the cache timeout hasn't expired.  Mutations can invalidate
 * these tags to force a refresh on the next request.
 */
export const api = createApi({
    baseQuery: ReauthBaseQuery,
    tagTypes: ["Follows", "Shares", "UserAgencies", "UserAdministration", "RadarConfigs", "KnowledgeBase", "Feedback"],
    endpoints: (builder) => ({
        // function that supports api calls prior to the user completing full authentication
        // account setup, login, change password, etc.
        runPreAuthApi: builder.mutation<any, { [key: string]: any; route: string }>({
            queryFn: async (params) => {
                try {
                    const res = await fetch(`${baseUrl}${params.route}`, {
                        method: "POST",
                        body: JSON.stringify(params),
                        headers: {
                            "Content-Type": "application/json",
                        },
                    });
                    const data = await res.json();
                    if (res.status === 200) {
                        return { data };
                    } else {
                        return { error: { status: "CUSTOM_ERROR", error: data.error || "other" } };
                    }
                } catch (e) {
                    return { error: { status: "CUSTOM_ERROR", error: "other" } };
                }
            },
        }),
        runPreEmailCheckSAMLOrDefaultApi: builder.mutation<any, { [key: string]: any }>({
            queryFn: async (params) => {
                try {
                    const res = await fetch(`${baseUrl}/v1/saml2/login/`, {
                        method: "POST",
                        body: JSON.stringify(params),
                        headers: {
                            "Content-Type": "application/json",
                        },
                    });
                    const data = await res.json();
                    if (res.status === 200) {
                        return { data };
                    } else {
                        return { error: { status: "CUSTOM_ERROR", error: data.error || "other" } };
                    }
                } catch (e) {
                    return { error: { status: "CUSTOM_ERROR", error: "other" } };
                }
            },
        }),
        changePassword: builder.mutation({
            query: (params) => ({
                url: "/v2/user/password-change",
                method: "POST",
                body: params,
            }),
        }),
        getEvidence: builder.query<any, any>({
            query: ({ incidentId }: { incidentId: Number }) => {
                return {
                    url: `/v1/integrations/axonevidence/${incidentId}`,
                    method: "GET",
                };
            },
            providesTags: ["UserAgencies"],
        }),
        getEvents: builder.query<any, any>({
            query: (params) => {
                let url = "/v2/search/search";
                return {
                    url,
                    method: "POST",
                    body: getBody(params),
                };
            },
            providesTags: ["UserAgencies"],
        }),
        getPeople: builder.query<any, any>({
            query: (params) => {
                return {
                    url: "/v2/search/people",
                    method: "POST",
                    body: getBody(params),
                };
            },
            providesTags: ["UserAgencies"],
        }),
        getPersonPhotosLatest: builder.query<any, any>({
            query: (params) => {
                return {
                    url: `/v1/integrations/person_photos/${params.person_id}`,
                    method: "GET",
                };
            },
            providesTags: ["UserAgencies"],
        }),
        getPersonPhotosAll: builder.query<any, any>({
            query: (params) => {
                return {
                    url: `/v1/integrations/person_photos/${params.person_id}/all`,
                    method: "GET",
                };
            },
            providesTags: ["UserAgencies"],
        }),
        getFuzzyPeople: builder.query<any, any>({
            query: (params) => {
                return {
                    url: "/v2/search/people-fuzzy",
                    method: "POST",
                    body: getBody(params),
                };
            },
            providesTags: ["UserAgencies"],
        }),
        getAddresses: builder.query<any, any>({
            query: (params) => {
                return {
                    url: "/v2/search/search-addresses",
                    method: "POST",
                    body: getBody(params),
                };
            },
            providesTags: ["UserAgencies"],
        }),
        getIncident: builder.query<any, any>({
            query: (params) => {
                const url = `${formatBaseApiAgencyUUIDUrl(params.agencyUUID)}/incidents`;
                return {
                    body: {
                        incident_id: params.incident_id,
                        search_for: params.query ? params.query : "",
                        call_number: params.id,
                        page_size: 10000,
                        formatted_results: params.formattedResults,
                    },
                    url,
                    method: "POST",
                };
            },
            providesTags: ["UserAgencies"],
        }),
        getNarratives: builder.query<any, any>({
            query: (params) => {
                const url = `${formatBaseApiAgencyUUIDUrl(params.agencyUUID)}/narratives`;
                return {
                    body: {
                        incident_id: params.id,
                        search_for: params.query ? params.query : "",
                        formatted_results: params.formattedResults,
                        page_size: 10000,
                    },
                    url,
                    method: "POST",
                };
            },
            providesTags: ["UserAgencies"],
        }),
        getTextSummarizations: builder.query<any, any>({
            query: (params) => {
                const url = `${formatBaseApiAgencyUUIDUrl(params.agencyUUID)}/text-summarizations`;
                return {
                    body: {
                        search_for: !!params.query ? params.query : "",
                        incident_id: params.id,
                        formatted_results: params.formattedResults,
                        page_size: 10000,
                    },
                    url,
                    method: "POST",
                };
            },
            providesTags: ["UserAgencies"],
        }),
        getCall: builder.query<any, any>({
            query: (params) => {
                let url = `/${formatBaseApiAgencyUUIDUrl(params.agencyUUID)}/calls`;

                return {
                    body: {
                        call_number: params.id,
                        search_for: !!params.query ? params.query : "",
                        page_size: 10000,
                        formatted_results: params.formattedResults,
                    },
                    url,
                    method: "POST",
                };
            },
            providesTags: ["UserAgencies"],
        }),
        getCallNotes: builder.query<any, any>({
            query: (params) => {
                let url = `/${formatBaseApiAgencyUUIDUrl(params.agencyUUID)}/call-notes`;

                return {
                    body: {
                        call_number: params.id,
                        search_for: !!params.query ? params.query : "",
                        page_size: 10000,
                        formatted_results: params.formattedResults,
                    },
                    url,
                    method: "POST",
                };
            },
            providesTags: ["UserAgencies"],
        }),
        getUserAgencies: builder.query({
            query: () => {
                const url = "v2/user/agencies";
                return {
                    url,
                    method: "GET",
                };
            },
            providesTags: ["UserAgencies"],
        }),
        updateUserAgencies: builder.mutation({
            query: (params) => {
                const url = "v2/user/agencies/switch";
                return {
                    url,
                    method: "POST",
                    body: {
                        id: params.id,
                    },
                };
            },
            invalidatesTags: ["Follows", "Shares", "UserAgencies", "UserAdministration", "RadarConfigs", "KnowledgeBase", "Feedback"],
        }),
        getUserDetails: builder.query({
            query: () => {
                const url = "/v2/user";
                return {
                    url,
                    method: "GET",
                };
            },
            providesTags: ["UserAgencies"],
        }),
        updateUserDetails: builder.mutation({
            query: (params) => {
                const url = "/v2/user";
                return {
                    url,
                    method: "PATCH",
                    body: {
                        first_name: params.firstName,
                        last_name: params.lastName,
                        phone: params.phone,
                        agency_position: params.agencyPosition,
                        security_level: params.securityLevel,
                        id: params.id,
                    },
                };
            },
            invalidatesTags: ["UserAgencies"],
        }),
        editUser: builder.mutation({
            query: (params) => {
                const url = "/v2/admin/users/edit-user";
                return {
                    url,
                    method: "PATCH",
                    body: {
                        first_name: params.firstName,
                        last_name: params.lastName,
                        phone: params.phone,
                        agency_position: params.agencyPosition,
                        security_level: params.securityLevel,
                        id: params.id,
                    },
                };
            },
            invalidatesTags: ["UserAdministration"],
        }),
        updateEulaAcceptance: builder.mutation({
            query: () => {
                const url = "v2/user/eula/accept";
                return {
                    url,
                    method: "POST",
                };
            },
            // invalidating user agencies as eula acceptance should modify user object
            invalidatesTags: ["UserAgencies"],
        }),
        getLogout: builder.query({
            query: (params) => {
                const url = "v2/auth/logout";
                return {
                    url,
                    method: "POST",
                    body: {
                        refresh: params.refresh,
                    },
                };
            },
        }),
        updateMFA: builder.query({
            query: () => {
                const url = "v2/auth/mfa/reset";
                return {
                    url,
                    method: "POST",
                };
            },
        }),
        getAddressDetail: builder.query<any, any>({
            query: (params) => {
                const url = `/${formatBaseApiAgencyUUIDUrl(params.agencyUUID)}/aggregated-address-detail`;
                return {
                    body: {
                        search_for: params.search_for ? params.search_for : "",
                        gps_lat: params.gps_lat,
                        gps_lon: params.gps_lon,
                        call_number: params.call_number,
                        full_address: params.full_address,
                        distance: params.distance,
                        page_size: 10000,
                        formatted_results: params.formattedResults,
                    },
                    url,
                    method: "POST",
                };
            },
            providesTags: ["UserAgencies"],
        }),
        getAddressResidents: builder.query<any, any>({
            query: (params) => {
                const url = `/${formatBaseApiAgencyUUIDUrl(params.agencyUUID)}/address-residents`;
                return {
                    body: {
                        search_for: params.search_for,
                        full_address: params.full_address,
                    },
                    url,
                    method: "POST",
                };
            },
            providesTags: ["UserAgencies"],
        }),
        getAddressEvents: builder.query<any, any>({
            query: (params) => {
                const url = `/${formatBaseApiAgencyUUIDUrl(params.agencyUUID)}/address-events`;
                return {
                    body: {
                        ...getBody(params),
                        search_for: params.search_for ? params.search_for : "",
                        gps_lat: params.gps_lat,
                        gps_lon: params.gps_lon,
                        full_address: params.full_address,
                        distance: params.distance,
                        page_size: params.pageSize,
                    },
                    url,
                    method: "POST",
                };
            },
            providesTags: ["UserAgencies"],
        }),
        getPremNotes: builder.query<any, any>({
            query: (params) => {
                const url = `/${formatBaseApiAgencyUUIDUrl(params.agencyUUID)}/prem-notes`;
                return {
                    body: getPremNotesBody(params),
                    url,
                    method: "POST",
                };
            },
            providesTags: ["UserAgencies"],
        }),
        getPersonIncidents: builder.query<any, any>({
            // agencyId is required in order to reconcile multiple people with same person id
            // in multiagency scenario
            query: ({ personId, agencyId }: { personId: string; agencyId: string }) => {
                return {
                    url: `/v3/search/agg/get_person_associated_incidents?person_id=${personId}&agency_id=${agencyId}`,
                    method: "GET",
                };
            },
            providesTags: ["UserAgencies"],
        }),
        getPersonAssociatedPeople: builder.query<any, any>({
            // agencyId is required in order to reconcile multiple people with same person id
            // in multiagency scenario
            query: ({ personId, agencyId }: { personId: string; agencyId: string }) => {
                return {
                    url: `/v3/search/agg/get_person_associated_people?person_id=${personId}&agency_id=${agencyId}`,
                    method: "GET",
                };
            },
            providesTags: ["UserAgencies"],
        }),
        getPersonPossibleConnections: builder.query<any, any>({
            query: (params) => {
                const url = `/${formatBaseApiAgencyUUIDUrl(params.agencyUUID)}/person-mentions`;
                return {
                    body: {
                        ...getBody(params),
                        first_name: params.first_name,
                        last_name: params.last_name,
                        dob: params.dob,
                        page_size: params.page_size,
                    },
                    url,
                    method: "POST",
                };
            },
            providesTags: ["UserAgencies"],
        }),
        getPerson: builder.query<any, any>({
            // agencyId is required in order to reconcile multiple people with same person id
            // in multiagency scenario
            query: ({ personId, agencyId }: { personId: string; agencyId: string }) => {
                return {
                    url: `/v3/search/basic/get_person?person_id=${personId}&agency_id=${agencyId}`,
                    method: "GET",
                };
            },
            providesTags: ["UserAgencies"],
        }),
        getPersonAliases: builder.query<any, any>({
            query: (params) => {
                const url = `/${formatBaseApiAgencyUUIDUrl(params.agencyUUID)}/person-aliases`;
                return {
                    body: {
                        person_id: params.personId,
                        parent_record_id: params.parentRecordId,
                        page_size: 100,
                        formatted_results: params.formattedResults,
                    },
                    url,
                    method: "POST",
                };
            },
            providesTags: ["UserAgencies"],
        }),
        getSearchHistory: builder.query({
            query: (params) => {
                return {
                    body: {
                        page_number: params.page || 1,
                        page_size: params.page_size,
                        formatted_results: params.formattedResults,
                    },
                    method: "POST",
                    url: "v2/search/search-history",
                };
            },
            providesTags: ["UserAgencies"],
        }),
        postFeedback: builder.mutation({
            query: (params) => ({
                url: "v2/misc/feedback",
                method: "POST",
                body: {
                    feedbackType: params.feedbackType,
                    feedback: params.feedback,
                    contactMe: params.contactMe,
                    page: params.page,
                    userAgent: navigator?.userAgent,
                },
            }),
            invalidatesTags: ["Feedback"],
        }),
        saveFollow: builder.mutation({
            query: (params) => ({
                url: "v2/user/follows",
                method: "POST",
                body: {
                    text: params.text,
                },
            }),
            invalidatesTags: ["Follows"],
        }),
        getFollowsHits: builder.query({
            query: () => {
                return {
                    url: "v2/user/follows-hits",
                    method: "GET",
                };
            },
            providesTags: ["Follows"],
        }),
        resetFollowTimestamp: builder.mutation({
            query: (params) => ({
                url: "v2/user/follows",
                method: "PUT",
                body: {
                    id: params.id,
                },
            }),
            invalidatesTags: ["Follows"],
        }),
        deleteFollow: builder.mutation({
            query: (params) => ({
                url: "v2/user/follows",
                method: "DELETE",
                body: {
                    id: params.id,
                },
            }),
            invalidatesTags: ["Follows"],
        }),
        getRadarConfigs: builder.query({
            query: () => ({
                url: "v2/user/radar-configs",
                method: "GET",
            }),
            providesTags: ["RadarConfigs"],
        }),
        createRadarConfig: builder.mutation({
            query: (params) => ({
                url: "v2/user/radar-configs",
                method: "POST",
                body: {
                    query_string_params: params.queryStringParams,
                    config_name: params.configName,
                },
            }),
            invalidatesTags: ["RadarConfigs"],
        }),
        setRadarConfigIsDefault: builder.mutation({
            query: (params) => ({
                url: `v2/user/radar-configs`,
                method: "PUT",
                body: params,
            }),
            invalidatesTags: ["RadarConfigs"],
        }),
        deleteRadarConfig: builder.mutation({
            query: (params) => ({
                url: "v2/user/radar-configs",
                method: "DELETE",
                body: {
                    id: params.id,
                },
            }),
            invalidatesTags: ["RadarConfigs"],
        }),
        getUserStatistics: builder.query({
            query: () => ({
                url: "v2/admin/metrics/user-stats",
                method: "GET",
            }),
            providesTags: ["UserAgencies"],
        }),
        getActiveUsers: builder.query({
            query: () => ({
                url: "v2/search/active-users",
                method: "POST",
            }),
            providesTags: ["UserAgencies"],
        }),
        getIndexStats: builder.query({
            query: () => ({
                url: "v2/search/index-stats",
                method: "POST",
            }),
            providesTags: ["UserAgencies"],
        }),
        getCommunityMetricsData: builder.query<any, any>({
            query: (params) => {
                let url = `v2/search/${params.api}`;

                if (params.result_type) {
                    if (params.day) {
                        return {
                            url,
                            method: "POST",
                            body: {
                                result_type: params.result_type,
                                day: params.day,
                            },
                        };
                    }

                    if (params.result_range) {
                        return {
                            url,
                            method: "POST",
                            body: {
                                result_type: params.result_type,
                                result_range: params.result_range,
                            },
                        };
                    }
                }
                return {
                    url,
                    method: "POST",
                };
            },
            providesTags: ["UserAgencies"],
        }),
        getUserStats: builder.query<any, any>({
            query: () => {
                const url = "v2/search/user-stats";
                return {
                    url,
                    method: "POST",
                };
            },
            providesTags: ["UserAgencies"],
        }),
        getSearchCountsDay: builder.query({
            query: () => {
                const url = "v2/search/search-counts-day";
                return {
                    url,
                    method: "POST",
                };
            },
            providesTags: ["UserAgencies"],
        }),
        getUserSearches: builder.query({
            query: (params) => {
                const url = "v2/search/user-searches";
                return {
                    body: {
                        page_number: params.page,
                        page_size: params.pageSize,
                        user_email: params.selectedUser ? params.selectedUser : undefined,
                        ...getBody(params),
                    },
                    url,
                    method: "POST",
                };
            },
            providesTags: ["UserAgencies"],
        }),
        getUsersList: builder.query({
            query: () => {
                let url = "v2/admin/users/members";

                return {
                    url,
                    method: "GET",
                };
            },
            providesTags: ["UserAdministration"],
        }),
        getShareUsersList: builder.query({
            query: () => {
                const url = "v2/user/agencies/share-users";

                return {
                    url,
                    method: "GET",
                };
            },
            providesTags: ["UserAgencies"],
        }),
        createUser: builder.mutation({
            query: (params) => {
                let url = "v2/admin/users/create";
                return {
                    url,
                    method: "POST",
                    body: {
                        first_name: params.firstName,
                        last_name: params.lastName,
                        email: params.email,
                        security_level: params.securityLevel || 0,
                    },
                };
            },
            invalidatesTags: ["UserAdministration"],
        }),
        setSecurityLevel: builder.mutation({
            query: (params) => {
                let url = "v2/admin/users/set-security-level";
                return {
                    url,
                    method: "POST",
                    body: {
                        user_id: params.user_id,
                        security_level: params.security_level,
                    },
                };
            },
            invalidatesTags: ["UserAdministration"],
        }),
        setUserPosition: builder.mutation({
            query: (params) => {
                let url = "v2/admin/users/set-position";
                return {
                    url,
                    method: "POST",
                    body: {
                        user_id: params.user_id,
                        position: params.position,
                    },
                };
            },
            invalidatesTags: ["UserAdministration"],
        }),
        deactivateUser: builder.mutation({
            query: (params) => {
                let url = "v2/admin/users/deactivate";
                return {
                    url,
                    method: "POST",
                    body: {
                        user_id: params.user_id,
                    },
                };
            },
            invalidatesTags: ["UserAdministration"],
        }),

        resendUserInvitation: builder.mutation({
            query: (params) => {
                let url = "v2/admin/users/send-invite";
                return {
                    url,
                    method: "POST",
                    body: {
                        user_id: params.user_id,
                    },
                };
            },
        }),
        reactivateUser: builder.mutation({
            query: (params) => {
                let url = "v2/admin/users/reactivate";
                return {
                    url,
                    method: "POST",
                    body: {
                        user_id: params.user_id,
                    },
                };
            },
            invalidatesTags: ["UserAdministration"],
        }),

        mfaResetUser: builder.mutation({
            query: (params) => {
                let url = "v2/admin/users/mfa-reset";
                return {
                    url,
                    method: "POST",
                    body: {
                        user_id: params.user_id,
                    },
                };
            },
            invalidatesTags: ["UserAdministration"],
        }),
        shareEntity: builder.mutation({
            query: (params) => {
                let url = "v2/user/share-url";
                return {
                    url,
                    method: "POST",
                    body: {
                        url: params.url,
                        emails: params.emails,
                        msg: params.msg,
                        title: params.title,
                    },
                };
            },
        }),
        getPageUrlFromShareUUID: builder.query<any, any>({
            query: (params) => {
                const url = "v2/user/share-uuid-to-url";
                return {
                    url,
                    method: "POST",
                    body: {
                        uuid: params.uuid,
                    },
                };
            },
            async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
                // wait for query to finish and then invalidate tags since agency may be switched
                await queryFulfilled;
                dispatch(
                    api.util.invalidateTags([
                        "Follows",
                        "Shares",
                        "UserAgencies",
                        "UserAdministration",
                        "RadarConfigs",
                        "KnowledgeBase",
                        "Feedback",
                    ])
                );
            },
        }),
        getRadar: builder.query<any, any>({
            query: (params) => {
                const url = "v2/search/radar";
                const updatedParams = { ...params };
                if (!updatedParams.query) {
                    updatedParams.query = "";
                }
                return {
                    body: {
                        ...getBody(updatedParams),
                        agency_uuid: params?.agencyUUID,
                        dont_save_search: true,
                        page_size: 10000,
                        radar: true,
                    },
                    url,
                    method: "POST",
                };
            },
            providesTags: ["UserAgencies"],
        }),
        getUniqueBeats: builder.query<any, any>({
            query: (params) => {
                const url = "v3/search/basic/get_beats";
                return {
                    body: getBody(params),
                    url,
                    method: "POST",
                };
            },
            keepUnusedDataFor: 30,
            providesTags: ["UserAgencies"],
        }),
        getUniqueGenders: builder.query<any, any>({
            query: () => {
                const url = "v2/search/unique_genders";
                return {
                    url,
                    method: "POST",
                };
            },
            providesTags: ["UserAgencies"],
        }),
        getUniqueRaces: builder.query<any, any>({
            query: () => {
                const url = "v2/search/unique_races";
                return {
                    url,
                    method: "POST",
                };
            },
            providesTags: ["UserAgencies"],
        }),
        getUniqueCallTypes: builder.query<any, any>({
            query: () => {
                const url = "v2/search/unique_call_types";
                return {
                    url,
                    method: "POST",
                };
            },
            providesTags: ["UserAgencies"],
        }),
        getUniqueCallSources: builder.query<any, any>({
            query: () => {
                const url = "v2/search/unique_call_sources";
                return {
                    url,
                    method: "POST",
                };
            },
            providesTags: ["UserAgencies"],
        }),
        getUniqueIncidentTypes: builder.query<any, any>({
            query: () => {
                const url = "v2/search/unique_incident_types";
                return {
                    url,
                    method: "POST",
                };
            },
            providesTags: ["UserAgencies"],
        }),
        getUniqueOffenses: builder.query<any, any>({
            query: () => {
                const url = "v2/search/unique_offenses";
                return {
                    url,
                    method: "POST",
                };
            },
            providesTags: ["UserAgencies"],
        }),
        getUniqueCallPriorities: builder.query<any, any>({
            query: () => {
                const url = "v2/search/unique_call_priorities";
                return {
                    url,
                    method: "POST",
                };
            },
            providesTags: ["UserAgencies"],
        }),
        getSharedUrlsForUser: builder.query<any, any>({
            query: (params) => {
                const url = "v2/user/share-url";
                return {
                    url,
                    method: "GET",
                };
            },
            providesTags: ["Shares", "UserAgencies"],
        }),
        getAllSharedUrls: builder.query({
            query: (params) => {
                const url = "v2/user/all-share-url";
                return {
                    url,
                    method: "GET",
                };
            },
            providesTags: ["UserAgencies"],
        }),
        getTotalEventsCount: builder.query<any, any>({
            query: (params) => {
                const url = "v2/search/total-event-count";
                return {
                    url,
                    method: "POST",
                    body: getBody(params),
                };
            },
            providesTags: ["UserAgencies"],
        }),
        getOfficerCount: builder.query<any, any>({
            query: (params) => {
                let url = "v2/search/officer-count";
                return {
                    url,
                    method: "POST",
                    body: getBody(params),
                };
            },
            providesTags: ["UserAgencies"],
        }),
        getDispatcherCount: builder.query<any, any>({
            query: (params) => {
                let url = "v2/search/dispatcher-count";
                return {
                    url,
                    method: "POST",
                    body: getBody(params),
                };
            },
            providesTags: ["UserAgencies"],
        }),
        getActivePeople: builder.query<any, any>({
            query: (params) => {
                let url = "v2/search/active-people";
                return {
                    url,
                    method: "POST",
                    body: getBody(params),
                };
            },
            providesTags: ["UserAgencies"],
        }),
        getCountsByBeats: builder.query<any, any>({
            query: (params) => {
                let url = "v2/search/counts-by-beats";
                return {
                    url,
                    method: "POST",
                    body: getBody(params),
                };
            },
            providesTags: ["UserAgencies"],
        }),
        getIncidentsByDay: builder.query<any, any>({
            query: (params) => {
                let url = "v2/search/incidents-by-day";
                return {
                    url,
                    method: "POST",
                    body: getBody(params),
                };
            },
            providesTags: ["UserAgencies"],
        }),
        getIncidentsByHour: builder.query<any, any>({
            query: (params) => {
                let url = "v2/search/incidents-by-hour";
                return {
                    url,
                    method: "POST",
                    body: getBody(params),
                };
            },
            providesTags: ["UserAgencies"],
        }),
        getIncidentsByTimeBlock: builder.query<any, any>({
            query: (params) => {
                let url = "v2/search/incidents-by-time-block";
                return {
                    url,
                    method: "POST",
                    body: getBody(params),
                };
            },
            providesTags: ["UserAgencies"],
        }),
        getInsightsIncidentsOverTime: builder.query<any, any>({
            query: (params) => {
                let url = "v2/search/insights-incidents-over-time";
                return {
                    url,
                    method: "POST",
                    body: getBody(params),
                };
            },
            providesTags: ["UserAgencies"],
        }),
        getOfficerList: builder.query<any, any>({
            query: () => {
                const url = "v2/search/officer-list";
                return {
                    url,
                    method: "POST",
                };
            },
            providesTags: ["UserAgencies"],
        }),
        getOfficerIncidentsByDay: builder.query<any, any>({
            query: (params) => {
                let url = "v2/search/officer-incidents-by-day";
                return {
                    url,
                    method: "POST",
                    body: getBody(params),
                };
            },
            providesTags: ["UserAgencies"],
        }),
        getOfficerIncidentsByHour: builder.query<any, any>({
            query: (params) => {
                let url = "v2/search/officer-incidents-by-hour";
                return {
                    url,
                    method: "POST",
                    body: getBody(params),
                };
            },
            providesTags: ["UserAgencies"],
        }),
        getOfficerIncidentsByTimeBlock: builder.query<any, any>({
            query: (params) => {
                let url = "v2/search/officer-incidents-by-time-block";
                return {
                    url,
                    method: "POST",
                    body: getBody(params),
                };
            },
            providesTags: ["UserAgencies"],
        }),
        getOfficerInsightsIncidentsOverTime: builder.query<any, any>({
            query: (params) => {
                let url = "v2/search/officer-insights-incidents-over-time";
                return {
                    url,
                    method: "POST",
                    body: getBody(params),
                };
            },
            providesTags: ["UserAgencies"],
        }),
        getOfficerCountsByBeats: builder.query<any, any>({
            query: (params) => {
                const url = "v2/search/officer-counts-by-beats";
                return {
                    url,
                    method: "POST",
                    body: getBody(params),
                };
            },
            providesTags: ["UserAgencies"],
        }),
        getDispatcherList: builder.query<any, any>({
            query: () => {
                const url = "v2/search/dispatcher-list";
                return {
                    url,
                    method: "POST",
                };
            },
            providesTags: ["UserAgencies"],
        }),
        getDispatcherCallsByDay: builder.query<any, any>({
            query: (params) => {
                let url = "v2/search/dispatcher-calls-by-day";
                return {
                    url,
                    method: "POST",
                    body: getBody(params),
                };
            },
            providesTags: ["UserAgencies"],
        }),
        getDispatcherCallsByHour: builder.query<any, any>({
            query: (params) => {
                let url = "v2/search/dispatcher-calls-by-hour";
                return {
                    url,
                    method: "POST",
                    body: getBody(params),
                };
            },
            providesTags: ["UserAgencies"],
        }),
        getDispatcherCallsByTimeBlock: builder.query<any, any>({
            query: (params) => {
                let url = "v2/search/dispatcher-calls-by-time-block";
                return {
                    url,
                    method: "POST",
                    body: getBody(params),
                };
            },
            providesTags: ["UserAgencies"],
        }),
        getDispatcherInsightsCallsOverTime: builder.query<any, any>({
            query: (params) => {
                let url = "v2/search/dispatcher-insights-calls-over-time";
                return {
                    url,
                    method: "POST",
                    body: getBody(params),
                };
            },
            providesTags: ["UserAgencies"],
        }),
        getDispatcherCountsByBeats: builder.query<any, any>({
            query: (params) => {
                const url = "v2/search/dispatcher-counts-by-beats";
                return {
                    url,
                    method: "POST",
                    body: getBody(params),
                };
            },
            providesTags: ["UserAgencies"],
        }),

        getKnowledgeBase: builder.query<any, any>({
            query: (params) => {
                let url = "v2/police/knowledge-base";
                return {
                    url,
                    method: "GET",
                };
            },
            providesTags: ["KnowledgeBase"],
        }),
        saveKnowledgeBaseArticle: builder.mutation({
            query: (params) => ({
                url: "v2/police/knowledge-base",
                method: "POST",
                body: {
                    title: params.title,
                    body: params.body,
                    agencies: params.agencies,
                },
            }),
            invalidatesTags: ["KnowledgeBase"],
        }),
        deleteKnowledgeBaseArticle: builder.mutation({
            query: (params) => ({
                url: "v2/police/knowledge-base",
                method: "DELETE",
                body: {
                    title: params.title,
                    id: params.id,
                },
            }),
            invalidatesTags: ["KnowledgeBase"],
        }),
        updateKnowledgeBaseArticle: builder.mutation({
            query: (params) => ({
                url: "v2/police/knowledge-base",
                method: "PUT",
                body: {
                    title: params.title,
                    body: params.body,
                    id: params.id,
                    agencies: params.agencies,
                    isEdit: params.isEdit,
                },
            }),
            invalidatesTags: ["KnowledgeBase"],
        }),
    }),
});

export const {
    useGetEvidenceQuery,
    useGetEventsQuery,
    useGetPeopleQuery,
    useGetFuzzyPeopleQuery,
    useGetPersonPhotosLatestQuery,
    useGetPersonPhotosAllQuery,
    useGetAddressesQuery,
    useGetIncidentQuery,
    useGetNarrativesQuery,
    useGetTextSummarizationsQuery,
    useGetCallQuery,
    useGetCallNotesQuery,
    useGetUserDetailsQuery,
    useUpdateUserDetailsMutation,
    useGetUserAgenciesQuery,
    useUpdateUserAgenciesMutation,
    useUpdateEulaAcceptanceMutation,
    useLazyGetLogoutQuery,
    useLazyUpdateMFAQuery,
    useGetSearchHistoryQuery,
    useGetPersonQuery,
    useGetPersonIncidentsQuery,
    useGetPersonAssociatedPeopleQuery,
    useGetPersonPossibleConnectionsQuery,
    useGetPersonAliasesQuery,
    useGetAddressDetailQuery,
    useGetAddressEventsQuery,
    useGetPremNotesQuery,
    usePostFeedbackMutation,
    useSaveFollowMutation,
    useResetFollowTimestampMutation,
    useDeleteFollowMutation,
    useGetFollowsHitsQuery,
    useGetUserStatisticsQuery,
    useGetCommunityMetricsDataQuery,
    useGetUserStatsQuery,
    useGetSearchCountsDayQuery,
    useGetUsersListQuery,
    useGetShareUsersListQuery,
    useCreateUserMutation,
    useSetSecurityLevelMutation,
    useSetUserPositionMutation,
    useDeactivateUserMutation,
    useReactivateUserMutation,
    useMfaResetUserMutation,
    useResendUserInvitationMutation,
    useShareEntityMutation,
    useGetPageUrlFromShareUUIDQuery,
    useGetUserSearchesQuery,
    useGetRadarQuery,
    useChangePasswordMutation,
    useGetUniqueBeatsQuery,
    useGetUniqueGendersQuery,
    useGetUniqueRacesQuery,
    useGetActiveUsersQuery,
    useGetIndexStatsQuery,
    useRunPreAuthApiMutation,
    useRunPreEmailCheckSAMLOrDefaultApiMutation,
    useGetUniqueIncidentTypesQuery,
    useGetUniqueOffensesQuery,
    useGetUniqueCallPrioritiesQuery,
    useGetUniqueCallTypesQuery,
    useGetUniqueCallSourcesQuery,
    useGetSharedUrlsForUserQuery,
    useGetAllSharedUrlsQuery,
    useGetTotalEventsCountQuery,
    useGetActivePeopleQuery,
    useGetCountsByBeatsQuery,
    useGetIncidentsByDayQuery,
    useGetIncidentsByHourQuery,
    useGetIncidentsByTimeBlockQuery,
    useGetInsightsIncidentsOverTimeQuery,
    useGetRadarConfigsQuery,
    useCreateRadarConfigMutation,
    useSetRadarConfigIsDefaultMutation,
    useDeleteRadarConfigMutation,
    useGetOfficerCountQuery,
    useGetOfficerListQuery,
    useGetOfficerIncidentsByDayQuery,
    useGetOfficerIncidentsByHourQuery,
    useGetOfficerIncidentsByTimeBlockQuery,
    useGetOfficerInsightsIncidentsOverTimeQuery,
    useGetOfficerCountsByBeatsQuery,
    useGetDispatcherCountQuery,
    useGetDispatcherListQuery,
    useGetDispatcherCallsByDayQuery,
    useGetDispatcherCallsByHourQuery,
    useGetDispatcherCallsByTimeBlockQuery,
    useGetDispatcherInsightsCallsOverTimeQuery,
    useGetDispatcherCountsByBeatsQuery,
    useGetKnowledgeBaseQuery,
    useSaveKnowledgeBaseArticleMutation,
    useDeleteKnowledgeBaseArticleMutation,
    useUpdateKnowledgeBaseArticleMutation,
    useGetAddressResidentsQuery,
    useEditUserMutation,
} = api;
