import React from "react";
import { Card } from "react-bootstrap";
import { useGetSearchCountsDayQuery } from "../../api/api";
import FMSpinner from "../Spinner";
import LineChart from "../charts/LineChart";

const SearchCountsByDay = ({ data }: any) => {
    return <LineChart processedData={data} xAxis="Month" yAxis="Counts" mainSeriesName="Counts" />;
};

const ConnectedSearchCountsByDay = () => {
    let { data, isLoading, isFetching } = useGetSearchCountsDayQuery({});
    if (!isLoading && data && data?.success && data?.aggregations && data?.aggregations?.over_time) {
        data = data?.aggregations?.over_time?.buckets?.reduce(
            (coll: any, curr: any) => {
                coll?.labels?.push(curr?.key_as_string);
                coll?.counts?.push(curr?.doc_count);
                return coll;
            },
            { labels: [], counts: [] }
        );
    }

    return (
        <Card>
            <Card.Header>Search Counts by Month</Card.Header>
            <Card.Body>
                {isLoading || isFetching ? (
                    <div className="d-flex justify-content-center align-items-center">
                        <FMSpinner />
                    </div>
                ) : (
                    <SearchCountsByDay data={data} />
                )}
            </Card.Body>
        </Card>
    );
};

export default ConnectedSearchCountsByDay;
