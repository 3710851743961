import React from "react";
import { Col, Container, Nav, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDatabase } from "@fortawesome/pro-solid-svg-icons";
import { useSetPageTitle } from "../../hooks/useSetPageTitle";
import UserStatistics from "../../components/userstatistics/UserStatistics";
import SearchCountsByDay from "../../components/searchcountbyday/SearchCountByDay";
import UserSearches from "../../components/usersearches/UserSearches";
import ActiveUsers from "../../components/activeusers/ActiveUsers";
import IndexStats from "../../components/indexstats/IndexStats";
import { Link, useParams } from "react-router-dom";
import usePermissions from "../../hooks/usePermissions";
import AllSharesConnected from "../shares/AllSharesReport";

enum TabEnum {
    stats = "stats",
    searchStats = "searchStats",
    userSearches = "userSearches",
    sharedURLs = "sharedURLs",
}

const AgencyMetricsDashboard = () => {
    const { hasPermission } = usePermissions();
    useSetPageTitle("ForceMetrics | App Stats");
    const { tab } = useParams();
    const invalidTab = tab && !(tab in TabEnum);

    return (
        <Container style={{ marginTop: "1em" }} fluid>
            <div className="d-flex justify-content-between">
                <h2>
                    <FontAwesomeIcon icon={faDatabase} className="me-2" />
                    Agency Metrics
                </h2>
            </div>

            <Nav variant="tabs" activeKey={tab || "stats"}>
                <Nav.Item>
                    <Nav.Link as={Link} to="/app/agency-metrics/stats" eventKey="stats">
                        Stats
                    </Nav.Link>
                </Nav.Item>
                {hasPermission("app_stats_search_stats") && (
                    <Nav.Item>
                        <Nav.Link as={Link} to="/app/agency-metrics/searchStats" eventKey="searchStats">
                            Search Stats
                        </Nav.Link>
                    </Nav.Item>
                )}
                {hasPermission("app_stats_user_searches") && (
                    <Nav.Item>
                        <Nav.Link as={Link} to="/app/agency-metrics/userSearches?dateRange=30days" eventKey="userSearches">
                            User Searches
                        </Nav.Link>
                    </Nav.Item>
                )}
                {hasPermission("app_stats_shared_urls") && (
                    <Nav.Item>
                        <Nav.Link as={Link} to="/app/agency-metrics/sharedURLs" eventKey="sharedURLs">
                            Shared URLs
                        </Nav.Link>
                    </Nav.Item>
                )}
            </Nav>
            <Row className="py-4 fm-bg-color mx-0 border border-top-0">
                {(invalidTab || tab === "stats") && (
                    <>
                        <Col md={4}>
                            <SearchCountsByDay />
                        </Col>
                        <Col md={4}>
                            <ActiveUsers />
                        </Col>
                        <Col md={4}>
                            <IndexStats />
                        </Col>
                    </>
                )}
                {hasPermission("app_stats_search_stats") && tab === "searchStats" && (
                    <Col sm={12}>
                        <UserStatistics />
                    </Col>
                )}
                {hasPermission("app_stats_user_searches") && tab === "userSearches" && (
                    <Col sm={12}>
                        <UserSearches />
                    </Col>
                )}
                {hasPermission("app_stats_shared_urls") && tab === "sharedURLs" && (
                    <Col sm={12}>
                        <AllSharesConnected />
                    </Col>
                )}
            </Row>
        </Container>
    );
};

export default AgencyMetricsDashboard;
