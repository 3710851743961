import React from "react";
import { formatDateMMDDYYYY } from "../../utils/date";
import CastleRockTags from "./CastleRockPersonTags";
import { WacoPersonTags } from "./WacoPersonTags";

type Tag = any;

const sortByDate = (a: Tag, b: Tag) => {
    return a?.when_modified > b?.when_modified ? 1 : -1;
};

const PersonTags = ({
    tags,
    showExpand = false,
    agencyId,
    centerTags = false,
}: {
    tags: Tag[];
    showExpand?: boolean;
    agencyId?: string;
    centerTags?: boolean;
}) => {
    const hasTags = tags?.length > 0 && Array.isArray(tags);
    let sortedTags = hasTags ? [...tags] : [];

    sortedTags = sortedTags?.sort(sortByDate);

    let component;
    switch (agencyId) {
        case "tx-wcpd1":
        case "nv-rnpd1":
            component = <WacoPersonTags tags={tags} showExpand={showExpand} />;
            break;
        case "co-crpd1":
        case "co-dcso1":
        case "ca-bhpd1":
        case "co-acso1":
        case "co-chpd1":
            component = <CastleRockTags tags={tags} showExpand={showExpand} />;
            break;
        default:
            component = hasTags ? (
                <div className={`${hasTags ? "" : "mb-3"} d-flex flex-wrap ${centerTags ? `justify-content-center` : ""}`}>
                    {sortedTags?.map((tag, index) => {
                        const formattedDate = tag?.when_modified ? formatDateMMDDYYYY(tag.when_modified) : null;
                        //These tags can contain long strings of info so need to make sure they wrap properly
                        return (
                            <span
                                className={`fs-5 d-inline-block text-wrap badge me-2 mb-1 align-content-bottom`}
                                style={{ backgroundColor: "#E54B4B" }}
                                key={`${tag.alert_code_description}+${index}`}
                            >
                                <div>{tag.alert_code_description}</div>
                                <div>{formattedDate ? `${formattedDate}` : ""}</div>
                            </span>
                        );
                    })}
                </div>
            ) : (
                <></>
            );
    }

    return component;
};

export default PersonTags;
