import React from "react";
import UserSettingsCard from "../../components/settings/UserSettingsCard";
import ChangePasswordForm from "../../components/settings/ChangePasswordForm";
import UserSettingsForm from "../../components/settings/PersonalInformationForm";
import MFAResetForm from "../../components/settings/MFAResetForm";
import ErrorBoundary from "../support/errorboundary/ErrorBoundary";
import { Accordion, Col, Container, Row } from "react-bootstrap";
import { useSetPageTitle } from "../../hooks/useSetPageTitle";
import NavBar from "../../components/settings/NavBar";
import LightDarkModeForm from "../../components/settings/LightDarkModeForm";
import { SettingsTabTypes } from "../../types/old_v1/types";
import { useAppSelector } from "../../app/hooks";
import usePermissions from "../../hooks/usePermissions";

type OwnProps = {
    user: any;
};

type ViewProps = {
    changeTab: Function;
    tabToDisplay: SettingsTabTypes;
    user: any;
};

const SettingsDesktopView = ({ changeTab, tabToDisplay, user }: ViewProps) => {
    const { hasPermission } = usePermissions();

    return (
        <Row className="d-none d-sm-flex">
            <Col sm={3} className="border-right">
                <NavBar currentTab={tabToDisplay} changeTab={(eventKey: any) => changeTab(eventKey as SettingsTabTypes)} />
            </Col>
            <Col sm={9}>
                <UserSettingsCard user={user} />
                <div className="mt-4">
                    {tabToDisplay === SettingsTabTypes.personalInformation ? <UserSettingsForm user={user} /> : null}
                    {hasPermission("user_change_password") && tabToDisplay === SettingsTabTypes.password ? <ChangePasswordForm /> : null}
                    {hasPermission("user_reset_mfa") && tabToDisplay === SettingsTabTypes.mfa ? <MFAResetForm /> : null}
                    {tabToDisplay === SettingsTabTypes.lightDarkMode ? <LightDarkModeForm /> : null}
                </div>
            </Col>
        </Row>
    );
};

const SettingsMobileView = ({ changeTab, tabToDisplay, user }: ViewProps) => {
    const { hasPermission } = usePermissions();
    return (
        <Row className="d-block d-sm-none">
            <UserSettingsCard user={user} />
            <Accordion defaultActiveKey={tabToDisplay} onSelect={(eventKey) => changeTab(eventKey as SettingsTabTypes)}>
                <Accordion.Item eventKey={SettingsTabTypes.personalInformation}>
                    <Accordion.Header>Personal Information</Accordion.Header>
                    <Accordion.Body>
                        <UserSettingsForm user={user} />
                    </Accordion.Body>
                </Accordion.Item>
                {hasPermission("user_change_password") && (
                    <Accordion.Item eventKey={SettingsTabTypes.password}>
                        <Accordion.Header>Change Password</Accordion.Header>
                        <Accordion.Body>
                            <ChangePasswordForm />
                        </Accordion.Body>
                    </Accordion.Item>
                )}
                {hasPermission("user_reset_mfa") && (
                    <Accordion.Item eventKey={SettingsTabTypes.mfa}>
                        <Accordion.Header>Multi-factor Authentication (MFA)</Accordion.Header>
                        <Accordion.Body>
                            <MFAResetForm />
                        </Accordion.Body>
                    </Accordion.Item>
                )}
                <Accordion.Item eventKey={SettingsTabTypes.lightDarkMode}>
                    <Accordion.Header>Light/dark mode</Accordion.Header>
                    <Accordion.Body>
                        <LightDarkModeForm />
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </Row>
    );
};

const SettingsPage = ({ user }: OwnProps) => {
    useSetPageTitle("ForceMetrics | Settings");

    React.useEffect(() => {
        return () => {
            localStorage.removeItem("settingsTab");
        };
    }, []);

    const defaultSettingsTab = SettingsTabTypes.personalInformation;
    const localStorageValue = localStorage.getItem("settingsTab") as SettingsTabTypes;
    const [tabToDisplay, setTabToDisplay] = React.useState<SettingsTabTypes>(
        Object.values(SettingsTabTypes).includes(localStorageValue) ? localStorageValue : defaultSettingsTab
    );

    const changeTab = (tab: SettingsTabTypes | null) => {
        const tabToSet = tab ?? defaultSettingsTab;
        setTabToDisplay(tabToSet);
        localStorage.setItem("settingsTab", tabToSet);
    };

    return (
        <ErrorBoundary>
            <Container className="overflow-auto" fluid={true}>
                <div className="mt-4">
                    <SettingsDesktopView changeTab={changeTab} tabToDisplay={tabToDisplay} user={user} />
                    <SettingsMobileView changeTab={changeTab} tabToDisplay={tabToDisplay} user={user} />
                </div>
            </Container>
        </ErrorBoundary>
    );
};

const ConnectedSettingsPage = () => {
    const user = useAppSelector(({ user }) => user);
    return <SettingsPage user={user.userObject} />;
};

export default ConnectedSettingsPage;
