import React from "react";
import { Alert } from "react-bootstrap";
import Highlighter from "../../../components/highlighter/Highlighter";
import CastleRockCallNotes from "./CastleRockCallNotes";
import WacoCallNotes from "./WacoCallNotes";
import useAgencySettings from "../../../hooks/useAgencySettings";

const BaseCallNotes = ({ callNotes }: { callNotes?: any }) => {
    const { callText } = useAgencySettings();

    if (!callNotes || !callNotes.length) {
        return <Alert variant="warning">No {callText} notes at this time. Data is processing.</Alert>;
    }

    const agencyId = callNotes[0]._source?.agency_id;
    let component;

    switch (agencyId) {
        case "tx-wcpd1":
            component = <WacoCallNotes callNotes={callNotes} />;
            break;
        case "co-acso1":
        case "co-arpd1":
        case "co-jfcm1":
            component = <CastleRockCallNotes callNotes={callNotes} sortBy="sequence_id" />;
            break;
        case "co-dcso1":
        case "ca-lmpd1":
        case "ca-bhpd1":
        case "co-crpd1":
            component = <CastleRockCallNotes callNotes={callNotes} />;
            break;
        default:
            component =
                !!callNotes && callNotes.length && Array.isArray(callNotes) ? (
                    <React.Fragment>
                        {callNotes.map((note: any, index: number) => {
                            return (
                                <p className="mb-1" key={index} style={{ whiteSpace: "pre-line" }}>
                                    <Highlighter>{note?.highlight?.body ? note.highlight.body[0] : note._source.body}</Highlighter>
                                </p>
                            );
                        })}
                    </React.Fragment>
                ) : null;
            break;
    }
    return component;
};

export default BaseCallNotes;
