import React from "react";
import { Formik } from "formik";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import * as Yup from "yup";

import { useUpdateUserDetailsMutation } from "../../api/api";
import { Alert, Col, Row } from "react-bootstrap";
import { formatDateWithoutTime } from "../../utils/date";
import { validators } from "../../utils/validators";
import EulaModal from "../../pages/EulaModal";
import { EmployeePositionsEnum } from "../../types/old_v1/types";
import { underToWords } from "../../utils/string";
import usePermissions from "../../hooks/usePermissions";

const getUserSettingsSchema = (isPhoneRequired: boolean) => {
    return Yup.object().shape({
        firstName: Yup.string().min(2, "Must be at least 2 characters").max(50, "Must be less than 50 characters").required("Required"),
        lastName: Yup.string().min(2, "Must be at least 2 characters").max(50, "Must be less than 50 characters").required("Required"),
        phone: isPhoneRequired ? validators.phone : validators.phone.notRequired().nullable(),
        agencyPosition: Yup.string().required("Required"),
    });
};

type OwnProps = {
    user: any;
};

const UserSettingsForm = ({ user }: OwnProps) => {
    const { hasPermission } = usePermissions();
    const [showEulaModal, setShowEulaModal] = React.useState<boolean>(false);
    const initialValues = {
        firstName: user.first_name,
        lastName: user.last_name,
        phone: user.phone,
        agencyPosition: user.agency_position,
    };

    const [updateUserDetails, { isLoading: isUpdating, isSuccess, isError }] = useUpdateUserDetailsMutation();

    const submitFn = (values: { firstName: string; lastName: string; phone: string; agencyPosition: EmployeePositionsEnum | null }) => {
        if (values) {
            updateUserDetails(values);
        }
    };

    const cannotChangePasswordAndResetMfa = !hasPermission("user_change_password") && !hasPermission("user_reset_mfa");

    const userSettingsSchema = getUserSettingsSchema(hasPermission("user_set_phone_number"));

    return (
        <Formik initialValues={initialValues} enableReinitialize={true} validationSchema={userSettingsSchema} onSubmit={submitFn}>
            {({ values, errors, touched, handleChange, handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <p>
                            Email Address: <span className="fw-bold text-primary">{user?.email}</span>
                        </p>
                    </Row>
                    <Row>
                        <Col xs={12} md={6} className="mb-3">
                            <Form.Group controlId="formFirstName">
                                <Form.Label className="w-100">First Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="firstName"
                                    onChange={handleChange}
                                    autoFocus={true}
                                    value={values.firstName || ""}
                                    isInvalid={!!errors.firstName && !!touched.firstName}
                                    disabled={cannotChangePasswordAndResetMfa}
                                />
                                {errors.firstName && touched.firstName ? <div className="text-danger">{errors.firstName}</div> : null}
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={6} className="mb-3">
                            <Form.Group controlId="formLastName">
                                <Form.Label className="w-100">Last Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="lastName"
                                    onChange={handleChange}
                                    value={values.lastName || ""}
                                    isInvalid={!!errors.lastName && !!touched.lastName}
                                    disabled={cannotChangePasswordAndResetMfa}
                                />
                                {errors.lastName && touched.lastName ? <div className="text-danger">{errors.lastName}</div> : null}
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={6} className="mb-3">
                            <Form.Group controlId="formPhone">
                                <Form.Label className="w-100">Phone</Form.Label>
                                <Form.Control
                                    type="phone"
                                    name="phone"
                                    onChange={handleChange}
                                    value={values.phone || ""}
                                    isInvalid={!!errors.phone && !!touched.phone}
                                    disabled={!hasPermission("user_set_phone_number")}
                                />
                                {errors.phone && touched.phone ? <div className="text-danger">{errors.phone}</div> : null}
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={6} className="mb-3">
                            <Form.Group controlId="formAgencyPosition">
                                <Form.Label className="w-100">Position</Form.Label>
                                <Form.Select
                                    value={values.agencyPosition || ""}
                                    name="agencyPosition"
                                    onChange={handleChange}
                                    isInvalid={!!errors.agencyPosition && !!touched.agencyPosition}
                                >
                                    <option value="">Choose position...</option>
                                    {Object.values(EmployeePositionsEnum).map((position: string) => (
                                        <option key={position} value={position}>
                                            {underToWords(position)}
                                        </option>
                                    ))}
                                </Form.Select>
                                {errors.agencyPosition && touched.agencyPosition ? (
                                    <div className="text-danger">{errors.agencyPosition}</div>
                                ) : null}
                            </Form.Group>
                        </Col>
                    </Row>
                    {isSuccess && <Alert variant="success">Profile saved successfully!</Alert>}
                    {isError && <Alert variant="danger">An error has occurred</Alert>}
                    <div className="mt-3">
                        {user.accepted_eula_at && (
                            <span data-testid="eula-accepted-message">
                                End User License Agreement accepted on {formatDateWithoutTime(user.accepted_eula_at)}.
                                <span className="d-inline-flex text-nowrap align-items-center">
                                    View agreement&nbsp;
                                    <Button variant="link" className="p-0" onClick={() => setShowEulaModal(true)}>
                                        here
                                    </Button>
                                    .
                                </span>
                            </span>
                        )}
                    </div>
                    <Button className="save-settings" type="submit" disabled={isUpdating}>
                        Save
                    </Button>
                    <EulaModal show={showEulaModal} close={() => setShowEulaModal(false)} />
                </Form>
            )}
        </Formik>
    );
};

export default UserSettingsForm;
