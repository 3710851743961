import React from "react";
import { Button, Card, Col, Container, Modal, Nav, OverlayTrigger, Popover, Row } from "react-bootstrap";
import EventOverview from "./EventOverview";
import ConnectedNarrativesView from "./NarrativesView";
import { useParams, useSearchParams } from "react-router-dom";
import { useSetPageTitle } from "../../hooks/useSetPageTitle";
import { useGetCallNotesQuery, useGetCallQuery, useGetEvidenceQuery, useGetIncidentQuery, useGetNarrativesQuery } from "../../api/api";
import LoadingModal from "../../components/LoadingModal";
import CallNotesView from "./CallNotesView";
import AssociatedPeopleView from "../../components/entity/AssociatedPeopleView";
import { CallNote, NewNarrative, SortConfig, SortDirectionEnum } from "../../types/old_v1/types";
import { EvidenceType } from "../../types/types";
import BackToSearchButton from "../../components/BackToSearchButton";
import { processNarrativeDataV2 } from "../../utils/old_v1/narratives";
import { scrollToTop } from "../../utils/helpers";
import SimpleAssociatedPeopleView from "../../components/entity/SimpleAssociatedPeopleView";
import ConnectedSimpleNarrativesView from "./SimpleNarrativesView";
import { useScrollToTopMultiDevice } from "../../hooks/useScrollToTopMultiDevice";
import useAgencySettings from "../../hooks/useAgencySettings";
import EvidenceView from "./EvidenceView";
import useIntegrations from "../../hooks/useIntegrations";
import ErrorBoundary from "../support/errorboundary/ErrorBoundary";
import OffensesView from "./OffensesView";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTableColumns } from "@fortawesome/pro-regular-svg-icons";

enum TabEnum {
    OVERVIEW = "overview",
    CALL_NOTES = "call-notes",
    NARRATIVES = "narratives",
    ASSOCIATED_PEOPLE = "associated-people",
    EVIDENCE = "evidence",
}

const peopleSortConfig: SortConfig = {
    birthdate: {
        label: "DOB",
        defaultSortDirection: SortDirectionEnum.ASCENDING,
        sortString: true,
    },
    "last_name,first_name": {
        label: "Name",
        defaultSortDirection: SortDirectionEnum.ASCENDING,
        sortString: true,
    },
    role: {
        label: "Role",
        defaultSortDirection: SortDirectionEnum.ASCENDING,
        sortString: true,
    },
};

const ConnectedEventPage = () => {
    const { agencyUUID, callId, incidentId } = useParams();
    const { incidentText, callText } = useAgencySettings();
    const { axonEvidenceEnabled } = useIntegrations();
    useSetPageTitle(`ForceMetrics${!!callId ? ` | ${callText} ${callId}` : ""}${!!incidentId ? ` | ${incidentText} ${incidentId}` : ""}`);

    const [searchParams, setSearchParams] = useSearchParams();
    const query = searchParams.get("query");
    const listRef = React.useRef<any>(null);

    // set current tab to url param if value in tab enum; else, default to overview tab
    const currentTab = Object.values(TabEnum).includes(searchParams.get("currentTab") as TabEnum)
        ? (searchParams.get("currentTab") as TabEnum)
        : TabEnum.OVERVIEW;

    const setCurrentTab = (value: TabEnum) => {
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.set("currentTab", value);
        setSearchParams(newSearchParams);
    };

    const { data: incidentResponse, isFetching: incidentFetching } = useGetIncidentQuery({
        formattedResults: true,
        query,
        incident_id: incidentId,
        id: callId,
        agencyUUID,
    });

    // if no callId is found from URL, then attempt to extract call number from incident response
    let callIdValue = callId;
    if (!callIdValue && incidentResponse?.incident?.source?.call_number?.length) {
        callIdValue = incidentResponse.incident.source.call_number[0];
    }
    const callParams = { query, id: callIdValue, agencyUUID };
    const { data: callResponse, isFetching: callFetching } = useGetCallQuery(
        { ...callParams, formattedResults: true },
        { skip: !callIdValue }
    );

    const { data: callNotesResponse, isFetching: isCallNotesFetching } = useGetCallNotesQuery(
        { id: callIdValue, query, agencyUUID, formattedResults: true },
        { skip: !callIdValue }
    );

    const derivedIncidentId = incidentId || incidentResponse?.incident?.source?.id;

    const { data: narrativesResponse, isFetching: isNarrativesFetching } = useGetNarrativesQuery(
        { id: derivedIncidentId, query, queryType: "cross_fields", agencyUUID, formattedResults: true },
        { skip: !derivedIncidentId }
    );
    const narratives =
        narrativesResponse && processNarrativeDataV2(narrativesResponse?.narratives, narrativesResponse.source_narratives, incidentId);

    const { data: evidenceResponse, isFetching: isEvidenceFetching } = useGetEvidenceQuery(
        { incidentId: derivedIncidentId },
        { skip: !axonEvidenceEnabled }
    );

    const highlightCountByTab = {
        [TabEnum.OVERVIEW]: 0,
        [TabEnum.CALL_NOTES]: callNotesResponse?.number_highlighted_fields || 0,
        [TabEnum.NARRATIVES]: narrativesResponse?.number_highlighted_fields || 0,
        [TabEnum.ASSOCIATED_PEOPLE]: 0,
        [TabEnum.EVIDENCE]: 0,
    };

    const pageResetDependencies = React.useMemo(
        () => ({ currentTab, searchParams, scrollToId: "mobileEventNav" }),
        [currentTab, searchParams]
    );

    // Scroll to top of page on every new load of the event page
    React.useEffect(() => {
        scrollToTop();
    }, []);

    // Only scroll to top of page when currentTab changes and the screensize is larger or equal to mobile size
    useScrollToTopMultiDevice(currentTab, searchParams, "mobileEventNav");

    return (
        <EventPage
            isIncidentFetching={incidentFetching}
            incident={incidentResponse?.incident}
            call={callResponse?.call}
            isCallFetching={callFetching}
            isCallNotesFetching={isCallNotesFetching}
            callNotes={callNotesResponse?.call_notes || []}
            isNarrativesFetching={isNarrativesFetching}
            narratives={narratives || []}
            isEvidenceFetching={isEvidenceFetching}
            evidence={evidenceResponse?.results || []}
            highlightCountByTab={highlightCountByTab}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            scrollToTop={scrollToTop}
            pageReset={pageResetDependencies}
            listRef={listRef}
        />
    );
};

type OwnProps = {
    call: any;
    isCallFetching: boolean;
    incident: any;
    isIncidentFetching: boolean;
    callNotes: CallNote[];
    isCallNotesFetching: boolean;
    narratives: NewNarrative[];
    isNarrativesFetching: boolean;
    isEvidenceFetching: boolean;
    evidence: EvidenceType[];
    highlightCountByTab: { [Tab in TabEnum]: number };
    currentTab: TabEnum;
    setCurrentTab: (value: TabEnum) => void;
    scrollToTop: any;
    pageReset: any;
    listRef: any;
};
const EventPage = ({
    call,
    isCallFetching,
    incident,
    isIncidentFetching,
    callNotes,
    isCallNotesFetching,
    narratives,
    isNarrativesFetching,
    isEvidenceFetching,
    evidence,
    highlightCountByTab,
    currentTab,
    setCurrentTab,
    scrollToTop,
    pageReset,
    listRef,
}: OwnProps) => {
    const [showSplitViewModal, setShowSplitViewModal] = React.useState(false);
    const narrativesTooltipMessage = `${highlightCountByTab[TabEnum.NARRATIVES]} ${
        highlightCountByTab[TabEnum.NARRATIVES] > 1 ? " narratives contain" : "narrative contains"
    } highlight`;

    return (
        <>
            <LoadingModal show={isCallFetching || isIncidentFetching} />
            <Container className="my-4" fluid="xl">
                <Row>
                    <Col className="mb-4 d-block d-md-none" xs={12}>
                        <BackToSearchButton />
                    </Col>
                    <Col xs={12} md={3} className="px-0 mb-4">
                        <EventOverview
                            incident={incident}
                            call={call}
                            isCallFetching={isCallFetching}
                            isIncidentFetching={isIncidentFetching}
                        />
                    </Col>
                    <Col xs={12} md={9} className="d-block flex-column px-0 px-md-4" ref={listRef}>
                        <Nav
                            className="d-none d-md-flex flex-nowrap border-bottom-0 justify-content-between"
                            variant="tabs"
                            activeKey={currentTab}
                        >
                            <div className="d-flex">
                                <EventPageDesktopNavItems
                                    currentTab={currentTab}
                                    setCurrentTab={setCurrentTab}
                                    highlightCountByTab={highlightCountByTab}
                                    isNarrativesFetching={isNarrativesFetching}
                                    isIncidentFetching={isIncidentFetching}
                                    narratives={narratives}
                                    isEvidenceFetching={isEvidenceFetching}
                                    evidenceLength={evidence.length}
                                    incident={incident}
                                    narrativesTooltipMessage={narrativesTooltipMessage}
                                    callNotesLength={callNotes.length}
                                />
                            </div>
                            <div className="align-self-center">
                                <OverlayTrigger
                                    placement="auto"
                                    trigger={["hover", "focus", "click"]}
                                    overlay={
                                        <Popover className="mw-100 hide-arrow">
                                            <Popover.Body>View side-by-side Call Notes & Narratives</Popover.Body>
                                        </Popover>
                                    }
                                >
                                    <Button
                                        variant="outline-primary"
                                        onClick={() => setShowSplitViewModal(true)}
                                        className="fs-4 px-2 py-1 pendo_event_page_split_view_button"
                                    >
                                        <FontAwesomeIcon icon={faTableColumns} />
                                    </Button>
                                </OverlayTrigger>
                            </div>
                        </Nav>
                        <Nav
                            id="mobileEventNav"
                            className="d-md-none gap-3 d-flex flex-column text-center p-4"
                            variant="pills"
                            activeKey={currentTab}
                        >
                            <EventPageMobileNavItems
                                currentTab={currentTab}
                                setCurrentTab={setCurrentTab}
                                highlightCountByTab={highlightCountByTab}
                                isNarrativesFetching={isNarrativesFetching}
                                isIncidentFetching={isIncidentFetching}
                                narratives={narratives}
                                isEvidenceFetching={isEvidenceFetching}
                                evidenceLength={evidence.length}
                                incident={incident}
                                narrativesTooltipMessage={narrativesTooltipMessage}
                                callNotesLength={callNotes.length}
                            />
                        </Nav>
                        <div className="min-vh-100-md h-auto fm-bg-color border-0 border-md rounded-end rounded-bottom p-4 d-flex flex-column flex-grow-1 mb-5">
                            {currentTab === TabEnum.OVERVIEW && (
                                <EventOverviewTabContent
                                    callNotes={callNotes}
                                    setCurrentTab={setCurrentTab}
                                    isCallNotesFetching={isCallNotesFetching}
                                    narratives={narratives}
                                    isNarrativesFetching={isNarrativesFetching}
                                    isIncidentFetching={isIncidentFetching}
                                    incident={incident}
                                    call={call}
                                />
                            )}
                            {currentTab === TabEnum.CALL_NOTES && <CallNotesView callNotes={callNotes} isLoading={isCallNotesFetching} />}
                            {currentTab === TabEnum.NARRATIVES && (
                                <div className="mt-3">
                                    <ConnectedNarrativesView
                                        narratives={narratives}
                                        isNarrativesFetching={isNarrativesFetching}
                                        incidentId={incident?.source?.id}
                                        scrollToTop={scrollToTop}
                                        pageReset={pageReset}
                                    />
                                </div>
                            )}
                            {currentTab === TabEnum.ASSOCIATED_PEOPLE && (
                                <AssociatedPeopleView
                                    associatedPeople={incident?.source?.involved_people || []}
                                    isLoading={isIncidentFetching}
                                    sortConfig={peopleSortConfig}
                                    defaultSortField="birthdate"
                                    defaultSortDirection={peopleSortConfig["birthdate"].defaultSortDirection}
                                    showRole={true}
                                    scrollToTop={scrollToTop}
                                    pageReset={pageReset}
                                    agencyId={incident?.source?.agency_id || call?.source?.agency_id}
                                />
                            )}
                            {currentTab === TabEnum.EVIDENCE && (
                                <ErrorBoundary errorComponentVariant="large">
                                    <EvidenceView
                                        incident={incident}
                                        evidence={evidence}
                                        isEvidenceFetching={isEvidenceFetching}
                                        pageReset={pageReset}
                                        scrollToTop={scrollToTop}
                                    />
                                </ErrorBoundary>
                            )}
                            <CallNotesNarrativesSplitViewModal
                                showSplitViewModal={showSplitViewModal}
                                setShowSplitViewModal={setShowSplitViewModal}
                                incident={incident}
                                callNotes={callNotes}
                                narratives={narratives}
                                isNarrativesFetching={isNarrativesFetching}
                                isCallNotesFetching={isCallNotesFetching}
                                pageReset={pageReset}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

type TEventPageNavItems = {
    currentTab: string;
    setCurrentTab: (value: TabEnum) => void;
    highlightCountByTab: { [Tab in TabEnum]: number };
    isNarrativesFetching: boolean;
    isIncidentFetching: boolean;
    narratives: any[];
    isEvidenceFetching: boolean;
    evidenceLength: number;
    incident: any;
    narrativesTooltipMessage: string;
    callNotesLength: number;
};

const EventPageDesktopNavItems = ({
    setCurrentTab,
    highlightCountByTab,
    isNarrativesFetching,
    narratives,
    isEvidenceFetching,
    evidenceLength,
    incident,
    isIncidentFetching,
    callNotesLength,
}: TEventPageNavItems) => {
    const { axonEvidenceEnabled } = useIntegrations();
    const { callText } = useAgencySettings();
    const noInvolvedPeople = !incident?.source?.involved_people || incident?.source?.involved_people?.length < 1;

    return (
        <>
            <Nav.Item>
                <Nav.Link
                    data-testid="overview-tab-button"
                    className="pendo_event_desktop_overview_tab px-3"
                    onClick={() => setCurrentTab(TabEnum.OVERVIEW)}
                    eventKey={TabEnum.OVERVIEW}
                >
                    Overview
                </Nav.Link>
            </Nav.Item>
            <Nav.Item className={`${callNotesLength < 1 ? "cursor-not-allowed" : ""}`}>
                <Nav.Link
                    data-testid="callnotes-tab-button"
                    className="pendo_event_desktop_call_notes_tab px-3"
                    disabled={callNotesLength < 1}
                    onClick={() => setCurrentTab(TabEnum.CALL_NOTES)}
                    eventKey={TabEnum.CALL_NOTES}
                >
                    <span className={highlightCountByTab[TabEnum.CALL_NOTES] > 0 ? "border-bottom border-2 border-warning" : ""}>
                        {callText} Notes
                    </span>
                </Nav.Link>
            </Nav.Item>
            <Nav.Item className={`${narratives?.length < 1 ? "cursor-not-allowed" : ""}`}>
                <Nav.Link
                    data-testid="narratives-tab-button"
                    className="pendo_event_desktop_narratives_tab px-3"
                    onClick={() => setCurrentTab(TabEnum.NARRATIVES)}
                    eventKey={TabEnum.NARRATIVES}
                    disabled={narratives?.length < 1}
                >
                    <span className={highlightCountByTab[TabEnum.NARRATIVES] > 0 ? "border-bottom border-2 border-warning" : ""}>
                        Narratives {isNarrativesFetching ? "" : narratives?.length > 0 && `- ${narratives.length}`}
                    </span>
                </Nav.Link>
            </Nav.Item>
            <Nav.Item className={`${noInvolvedPeople ? "cursor-not-allowed" : ""}`}>
                <Nav.Link
                    data-testid="associated-people-tab-button"
                    className="pendo_event_desktop_people_tab px-3"
                    onClick={() => setCurrentTab(TabEnum.ASSOCIATED_PEOPLE)}
                    eventKey={TabEnum.ASSOCIATED_PEOPLE}
                    disabled={noInvolvedPeople}
                >
                    Associated People
                    {isIncidentFetching
                        ? ""
                        : incident?.source?.involved_people?.length > 0 && ` - ${incident?.source?.involved_people?.length}`}
                </Nav.Link>
            </Nav.Item>
            {axonEvidenceEnabled && (
                <Nav.Item className={`${evidenceLength < 1 ? "cursor-not-allowed" : ""}`}>
                    <Nav.Link
                        data-testid="evidence-tab-button"
                        className="pendo_event_desktop_evidence_tab px-3"
                        onClick={() => setCurrentTab(TabEnum.EVIDENCE)}
                        eventKey={TabEnum.EVIDENCE}
                        disabled={evidenceLength === 0}
                    >
                        Evidence
                        {isEvidenceFetching ? "" : evidenceLength > 0 && ` - ${evidenceLength}`}
                    </Nav.Link>
                </Nav.Item>
            )}
        </>
    );
};

const EventPageMobileNavItems = ({
    currentTab,
    setCurrentTab,
    highlightCountByTab,
    isNarrativesFetching,
    narratives,
    isEvidenceFetching,
    evidenceLength,
    incident,
    isIncidentFetching,
    callNotesLength,
}: TEventPageNavItems) => {
    const { axonEvidenceEnabled } = useIntegrations();
    const { callText } = useAgencySettings();
    const noInvolvedPeople = !incident?.source?.involved_people || incident?.source?.involved_people?.length < 1;
    return (
        <>
            <Nav.Item>
                <Nav.Link
                    className={`${
                        currentTab === TabEnum.OVERVIEW ? "active" : ""
                    } rounded border border-primary pendo_event_mobile_overview_tab`}
                    onClick={() => setCurrentTab(TabEnum.OVERVIEW)}
                    eventKey={TabEnum.OVERVIEW}
                >
                    Overview
                </Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link
                    className={`${
                        currentTab === TabEnum.CALL_NOTES ? "active" : ""
                    } rounded border border-primary pendo_event_mobile_notes_tab`}
                    onClick={() => setCurrentTab(TabEnum.CALL_NOTES)}
                    eventKey={TabEnum.CALL_NOTES}
                    disabled={callNotesLength < 1}
                >
                    <span className={highlightCountByTab[TabEnum.CALL_NOTES] > 0 ? "border-bottom border-2 border-warning" : ""}>
                        {callText} Notes
                    </span>
                </Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link
                    className={`${
                        currentTab === TabEnum.NARRATIVES ? "active" : ""
                    } rounded border border-primary pendo_event_mobile_narratives_tab`}
                    onClick={() => setCurrentTab(TabEnum.NARRATIVES)}
                    eventKey={TabEnum.NARRATIVES}
                    disabled={narratives?.length < 1}
                >
                    <span className={highlightCountByTab[TabEnum.NARRATIVES] > 0 ? "border-bottom border-2 border-warning" : ""}>
                        Narratives {isNarrativesFetching ? "" : narratives?.length > 0 && `- ${narratives.length}`}
                    </span>
                </Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link
                    className={`${
                        currentTab === TabEnum.ASSOCIATED_PEOPLE ? "active" : ""
                    } rounded border border-primary pendo_event_mobile_people_tab`}
                    onClick={() => setCurrentTab(TabEnum.ASSOCIATED_PEOPLE)}
                    eventKey={TabEnum.ASSOCIATED_PEOPLE}
                    disabled={noInvolvedPeople}
                >
                    Associated People
                    {isIncidentFetching
                        ? ""
                        : incident?.source?.involved_people?.length > 0 && ` - ${incident?.source?.involved_people?.length}`}
                </Nav.Link>
            </Nav.Item>
            {axonEvidenceEnabled && (
                <Nav.Item>
                    <Nav.Link
                        className={`${
                            currentTab === TabEnum.EVIDENCE ? "active" : ""
                        } rounded border border-primary pendo_event_mobile_evidence_tab`}
                        onClick={() => setCurrentTab(TabEnum.EVIDENCE)}
                        eventKey={TabEnum.EVIDENCE}
                        disabled={evidenceLength < 1}
                    >
                        Evidence
                        {isEvidenceFetching ? "" : evidenceLength > 0 && ` - ${evidenceLength}`}
                    </Nav.Link>
                </Nav.Item>
            )}
        </>
    );
};

type TEventOverviewTabContent = {
    call: any;
    incident: any;
    callNotes: CallNote[];
    isCallNotesFetching: boolean;
    narratives: NewNarrative[];
    isNarrativesFetching: boolean;
    isIncidentFetching: boolean;
    setCurrentTab: (value: TabEnum) => void;
};

const EventOverviewTabContent = ({
    callNotes,
    setCurrentTab,
    isCallNotesFetching,
    narratives,
    isNarrativesFetching,
    isIncidentFetching,
    incident,
    call,
}: TEventOverviewTabContent) => {
    const { callText } = useAgencySettings();

    return (
        <>
            <Card className="mb-4 overflow-hidden">
                <Card.Header className="d-flex justify-content-between align-items-center">
                    <Card.Title className="fw-bold mb-0 fs-4">{callText} Notes</Card.Title>
                    <Button
                        disabled={callNotes?.length > 0 ? false : true}
                        variant="link"
                        size="sm"
                        onClick={() => setCurrentTab(TabEnum.CALL_NOTES)}
                        className="pendo_event_overview_call_notes"
                    >
                        Go to {callText} Notes
                    </Button>
                </Card.Header>
                <Card.Body>
                    <div className="mt-3 overflow-hidden">
                        <CallNotesView
                            callNotes={callNotes}
                            isLoading={isCallNotesFetching}
                            isTruncated
                            setCurrentTab={() => setCurrentTab(TabEnum.CALL_NOTES)}
                        />
                    </div>
                </Card.Body>
            </Card>
            <Card className="mb-4 overflow-hidden">
                <Card.Header className="d-flex justify-content-between align-items-center">
                    <Card.Title className="fw-bold mb-0 fs-4">Narratives - {narratives?.length || "0"}</Card.Title>
                    <Button
                        className="pendo_event_overview_narratives"
                        variant="link"
                        disabled={narratives?.length < 1}
                        size="sm"
                        onClick={() => setCurrentTab(TabEnum.NARRATIVES)}
                    >
                        Go to Narratives
                    </Button>
                </Card.Header>
                <Card.Body>
                    <ConnectedSimpleNarrativesView
                        narratives={narratives}
                        isNarrativesFetching={isNarrativesFetching}
                        incidentId={incident?.source?.id}
                        setCurrentTab={() => setCurrentTab(TabEnum.NARRATIVES)}
                    />
                </Card.Body>
            </Card>
            <Card className="mb-4 overflow-hidden">
                <Card.Header className="d-flex justify-content-between align-items-center">
                    <Card.Title className="fw-bold mb-0 fs-4">
                        Associated People - {incident?.source?.involved_people?.length || "0"}
                    </Card.Title>
                    <Button
                        variant="link"
                        disabled={incident?.source?.involved_people?.length > 0 ? false : true}
                        size="sm"
                        onClick={() => setCurrentTab(TabEnum.ASSOCIATED_PEOPLE)}
                        className="pendo_event_overview_people"
                    >
                        Go to Associated People
                    </Button>
                </Card.Header>
                <Card.Body>
                    <SimpleAssociatedPeopleView
                        associatedPeople={incident?.source?.involved_people || []}
                        isLoading={isIncidentFetching}
                        showRole={true}
                        agencyId={incident?.source?.agency_id || call?.source?.agency_id}
                        setCurrentTab={() => setCurrentTab(TabEnum.ASSOCIATED_PEOPLE)}
                    />
                </Card.Body>
            </Card>
            <Card className="overflow-hidden">
                <Card.Header className="d-flex justify-content-between align-items-center">
                    <Card.Title className="fw-bold my-2 fs-4">Offenses - {incident?.source?.offenses?.length || "0"}</Card.Title>
                </Card.Header>
                <Card.Body>
                    <ErrorBoundary>
                        <OffensesView isLoading={isIncidentFetching} offenses={incident?.source?.offenses || []} />
                    </ErrorBoundary>
                </Card.Body>
            </Card>
        </>
    );
};

interface ICallNotesNarrativesSplitViewModal {
    showSplitViewModal: boolean;
    setShowSplitViewModal: Function;
    callNotes: CallNote[];
    isNarrativesFetching: boolean;
    isCallNotesFetching: boolean;
    narratives: any[];
    incident: any;
    pageReset: any;
}

const CallNotesNarrativesSplitViewModal = ({
    showSplitViewModal,
    setShowSplitViewModal,
    callNotes,
    isCallNotesFetching,
    narratives,
    isNarrativesFetching,
    incident,
    pageReset,
}: ICallNotesNarrativesSplitViewModal) => (
    <Modal
        className="d-none d-md-block"
        backdropClassName="d-none d-md-block"
        fullscreen
        show={showSplitViewModal}
        onHide={() => setShowSplitViewModal(false)}
    >
        <Modal.Header className="d-flex flex-col" closeButton={true}>
            <div className="w-50 ps-3">Call Notes</div>
            <div className="w-50 ps-4">Narratives</div>
        </Modal.Header>
        <Modal.Body className="d-flex flex-col">
            <div className="w-50 px-3">
                <CallNotesView callNotes={callNotes} isLoading={isCallNotesFetching} />
            </div>
            <div className="w-50 px-3">
                <ConnectedNarrativesView
                    narratives={narratives}
                    isNarrativesFetching={isNarrativesFetching}
                    incidentId={incident?.source?.id}
                    scrollToTop={scrollToTop}
                    pageReset={pageReset}
                />
            </div>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowSplitViewModal(false)}>
                Close
            </Button>
        </Modal.Footer>
    </Modal>
);

export default ConnectedEventPage;
