import { Outlet, useParams } from "react-router-dom";
import { useMultiAgency } from "../../hooks/useMultiAgency";
import Unauthorized from "../support/Unauthorized";

/**
 * Process the agency UUID on the route and determine
 * whether it is valid. The UUID is valid in two cases:
 *   1. The parent agency is not multi-agency and the
 *      uuid from the url matches the parent agency's
 *      uuid
 *   2. The parent agency is multi-agency and the uuid
 *      from the url matches one of the sub agencies'
 *      uuids
 * @returns either an Outlet to render the actual content
 *          or a Navigate to the 403 unauthorized route
 */
const ValidateAgencyUUID = () => {
    const { agencyUUID } = useParams();
    const { parentAgencyUUID, isMultiAgency, userSubAgencies } = useMultiAgency();

    const userSubAgenciesUUIDs = userSubAgencies?.length ? Object.values(userSubAgencies).map((subAgency: any) => subAgency?.fm_uuid) : [];

    if ((isMultiAgency && userSubAgenciesUUIDs.includes(agencyUUID)) || (!isMultiAgency && agencyUUID === parentAgencyUUID)) {
        return <Outlet />;
    }

    return <Unauthorized />;
};

export default ValidateAgencyUUID;
