import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { Follow, User } from "./types";
import { OnboardingObject } from "../../../types/old_v1/types";
import { FeedbackStatus } from "../../../types/types";

type UserAgencyCoords = {
    latitude: number;
    longitude: number;
    zoom: number;
};

export interface UserState {
    pwResetToken: string | null;
    user: User | null;
    status: string;
    toastDelay: number;
    toastMessage: string | undefined;
    isLoggedIn: boolean;
    mfaData: MfaResponse | undefined;
    showSitePickerModal: boolean;
    showFeedbackModal: boolean;
    feedbackStatus: FeedbackStatus;
    showDarkLightModeModal: boolean;
    currentCommunityTab: string | undefined;
    isForceMetricsUser: boolean;
    userAgency: string;
    userAgencyCoords: UserAgencyCoords;
    userObject: any;
    userMapZoom: any;
    onboardingObject: any;
    follows: Follow[];
    shownPositionPrompt: boolean;
    refreshRunning: boolean;
}

const initialMapCenter = {
    latitude: 39.8283,
    longitude: -98.5795,
    zoom: 4.5,
};

const initialState: UserState = {
    pwResetToken: null,
    user: null,
    status: "idle",
    toastDelay: 3000,
    toastMessage: undefined,
    isLoggedIn: false,
    mfaData: undefined,
    showSitePickerModal: false,
    showFeedbackModal: false,
    feedbackStatus: "bug",
    showDarkLightModeModal: false,
    currentCommunityTab: undefined,
    isForceMetricsUser: false,
    userAgency: "",
    userObject: undefined,
    userAgencyCoords: initialMapCenter,
    userMapZoom: 100,
    onboardingObject: undefined,
    follows: [],
    shownPositionPrompt: false,
    refreshRunning: false,
};

type MfaResponse = {
    mfa?: string;
    email?: string;
    mfaUrl?: string;
    mfaEnabled?: boolean;
    mfaType?: string;
    phone?: string;
};

type FeedbackPayload = {
    show: boolean;
    status?: FeedbackStatus;
};

export const userSlice = createSlice({
    name: "user",
    initialState: { ...initialState } as UserState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setToastMessage: (state, action: PayloadAction<string | undefined>) => {
            state.toastDelay = initialState.toastDelay;
            state.toastMessage = action.payload;
        },
        setIsLoggedIn: (state, action: PayloadAction<boolean>) => {
            state.isLoggedIn = action.payload;
        },
        setMfaData: (state, action: PayloadAction<MfaResponse>) => {
            state.mfaData = action.payload;
        },
        setShowSitePickerModal: (state, action: PayloadAction<boolean>) => {
            state.showSitePickerModal = action.payload;
        },
        setShowFeedbackModal: (state, action: PayloadAction<FeedbackPayload>) => {
            state.showFeedbackModal = action.payload.show;
            if (action.payload.status) {
                state.feedbackStatus = action.payload.status;
            }
        },
        setUserAgencyCoords: (state, action: PayloadAction<UserAgencyCoords>) => {
            state.userAgencyCoords = action.payload;
        },
        resetUserAgencyCoords: (state) => {
            state.userAgencyCoords = initialMapCenter;
        },
        setUserObject: (state, action: PayloadAction<any>) => {
            state.userObject = action.payload;
        },
        setOnboardingObject: (state, action: PayloadAction<OnboardingObject>) => {
            // onboardingObject has mfa: token, mfaUrl: url for qr code, email: the user's email
            state.onboardingObject = action.payload;
            state.isLoggedIn = true;
        },
        clearState: (state) => {
            storage.removeItem("persist:root");
            state = initialState;
        },
        setFollowsList: (state, action: PayloadAction<Follow[]>) => {
            state.follows = action.payload;
        },
        setShownPositionPrompt: (state, action: PayloadAction<boolean>) => {
            state.shownPositionPrompt = action.payload;
        },
    },
});

export const {
    setToastMessage,
    setIsLoggedIn,
    setMfaData,
    setShowSitePickerModal,
    setShowFeedbackModal,
    setUserAgencyCoords,
    resetUserAgencyCoords,
    setUserObject,
    clearState,
    setOnboardingObject,
    setFollowsList,
    setShownPositionPrompt,
} = userSlice.actions;

export default userSlice.reducer;
