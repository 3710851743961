import { useAppSelector } from "../../app/hooks";
import React from "react";
import { ListGroup, ListGroupItem, Modal, ModalBody } from "react-bootstrap";
import FMSpinner from "../Spinner";
import { useSearchBoxCore } from "@mapbox/search-js-react";

import "./AddressSearchModal.css";
import { Link } from "react-router-dom";
import useNextAwarePathname from "../../hooks/useNextAwarePathname";

type ConnectedProps = {
    show: boolean;
    hideModalFn: () => void;
    prefillSearch: string;
};

type OwnProps = {
    show: boolean;
    hideModalFn: () => void;
    isLoading: boolean;
    searchResults: any[];
    query: string;
    setQuery: React.Dispatch<React.SetStateAction<string>>;
};

const ConnectedAddressSearchModal = ({ show, hideModalFn, prefillSearch }: ConnectedProps) => {
    const [query, setQuery] = React.useState("");
    const [debouncedQuery, setDebouncedQuery] = React.useState("");

    const [isLoading, setIsLoading] = React.useState(false);
    const [searchResults, setSearchResults] = React.useState([] as any[]);
    const user = useAppSelector((state) => state.user.userObject);
    const [latitude, longitude] = user?.agency_coordinates || ["", ""];

    // format proximity coordinates for search
    const proximity = latitude && longitude ? `${longitude},${latitude}` : "";

    let searchBoxCore = useSearchBoxCore({
        accessToken: window?.__RUNTIME_CONFIG__?.REACT_APP_MAPBOX_TOKEN || "",
        language: "en",
        country: "us",
        proximity,
        types: "address,poi",
    });

    // debounce setting debouncedQuery in order to prevent several API calls in quick succession
    React.useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedQuery(query);
        }, 300);

        return () => {
            clearTimeout(handler);
        };
    }, [query]);

    React.useEffect(() => {
        setQuery(prefillSearch);
        setDebouncedQuery(prefillSearch);
    }, [prefillSearch]);

    // re-query for search results when debouncedQuery changes
    React.useEffect(() => {
        if (show && !!debouncedQuery) {
            setIsLoading(true);

            searchBoxCore
                ?.suggest(debouncedQuery, { sessionToken: "test-123" })
                ?.then((results: any) => {
                    if (results) {
                        setSearchResults(results?.suggestions || []);
                    }
                })
                ?.catch((error: any) => {
                    // Just log the error from mapbox
                    console.error(error);
                })
                ?.finally(() => {
                    setIsLoading(false);
                });
        } else {
            setSearchResults([]);
            setIsLoading(false);
        }
    }, [debouncedQuery, proximity, searchBoxCore, show]);

    return (
        <AddressSearchModal
            show={show}
            hideModalFn={hideModalFn}
            isLoading={isLoading}
            searchResults={searchResults}
            query={query}
            setQuery={setQuery}
        />
    );
};

export const AddressSearchModal = ({ show, hideModalFn, isLoading, searchResults, query, setQuery }: OwnProps) => {
    const { canPerformNextFESearch, mainSearchPath } = useNextAwarePathname();

    const MainSearchLink = () => {
        const querySearchParams = new URLSearchParams({ query });
        const mainSearchUrl = `${mainSearchPath}?${querySearchParams.toString()}`;

        return canPerformNextFESearch ? (
            <a href={mainSearchUrl}>Try searching your data for this address</a>
        ) : (
            <Link to={mainSearchUrl}>Try searching your data for this address</Link>
        );
    };

    return (
        <Modal show={show} onHide={hideModalFn} data-testid="address-search-modal">
            <Modal.Header closeButton>
                <Modal.Title>Select Address</Modal.Title>
            </Modal.Header>
            <ModalBody className="d-flex flex-column">
                <input
                    autoFocus
                    type="text"
                    value={query || ""}
                    className="form-control mb-4"
                    name="mapbox-address-search-input"
                    onChange={(e) => setQuery(e.target.value)}
                    placeholder="Search by address"
                />
                {isLoading && (
                    <div className="mb-4 d-flex justify-content-center">
                        <FMSpinner centered />
                    </div>
                )}
                {searchResults.length > 0 ? (
                    <>
                        <ListGroup className="border border-bottom-0 address-results-list">
                            {searchResults.map((result) => (
                                <ListGroupItem
                                    key={result?.mapbox_id}
                                    action
                                    className="d-flex flex-column cursor-pointer border-0 border-bottom"
                                    data-testid="search-result"
                                    // using window.location.assign instead of navigate to properly redirect to the location details page
                                    onClick={() => window.location.assign(`/details/location/${result?.mapbox_id}`)}
                                >
                                    <span>{result?.name}</span>
                                    <small className="text-secondary">{result?.full_address || result?.place_formatted}</small>
                                </ListGroupItem>
                            ))}
                        </ListGroup>
                        <div className="d-flex flex-column align-items-center mt-3">
                            <span>Don't see what you're looking for?</span>
                            <MainSearchLink />
                        </div>
                    </>
                ) : (
                    !isLoading && (
                        <div className="d-flex flex-column align-items-center">
                            {query.trim() !== "" ? (
                                <>
                                    <span className="text-secondary">No results found.</span>
                                    <MainSearchLink />
                                </>
                            ) : (
                                <span className="text-secondary text-center">
                                    Enter an address or name of a place to see suggested locations.
                                </span>
                            )}
                        </div>
                    )
                )}
                <small className="text-end mt-3">Powered by Mapbox</small>
            </ModalBody>
        </Modal>
    );
};

export default ConnectedAddressSearchModal;
