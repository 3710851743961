import React from "react";
import { useSearchParams } from "react-router-dom";
import { Button, Form, OverlayTrigger, Popover, Row, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilterList, faStar, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { faStar as fasStar } from "@fortawesome/pro-solid-svg-icons";
import { presetConfigs, viewToString } from "./utils";
// v1 api
import { useGetRadarConfigsQuery, useSetRadarConfigIsDefaultMutation } from "../../api/api";
import RadarDeleteConfigModal from "./RadarDeleteConfigModal";
import { RadarConfigNameEnum } from "../../types/types";

const RadarFirstClassFilter = ({ close, clearFilters }: { close: Function; clearFilters: Function }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [deleteConfig, setDeleteConfig] = React.useState<any>(null);
    const [sortedConfigs, setSortedConfigs] = React.useState<any>([]);
    const view = searchParams.get("view") ?? "";
    const [savedViewConfig, setSavedViewConfig] = React.useState<any>(null);
    // isSelected enables the delete modal to populate the query params to CUSTOM from the deleted config
    // otherwise, it just deletes the chosen config and remains on the selected view
    const [isSelected, setIsSelected] = React.useState(false);
    const [setRadarConfigIsDefault] = useSetRadarConfigIsDefaultMutation();

    const { data: radarConfigsData } = useGetRadarConfigsQuery({});
    const radarConfigs = radarConfigsData && radarConfigsData.results;

    React.useEffect(() => {
        // initial set of view
        const hasDefault = radarConfigsData?.results?.find((item: any) => item.is_default);
        const view = searchParams.get("view") ?? "";
        const defaultQueryStringParamsCheck = hasDefault?.query_string_params === searchParams.toString();

        // viewToString(view).length checks to see if we were on a predefined view
        // checking query_string_params of default against current searchParams to decide if it needs to be updated for default
        // this ensures we don't change away from a predefined config.
        // after hasDefault check, we want to determine if the view is not predefined && equals the defaultParams
        // OR equals the defaultParams and hasDefault, then we know to update or not.
        // This is important for going back functionality or dropping in a link
        if (hasDefault && ((viewToString(view).length === 0 && defaultQueryStringParamsCheck) || defaultQueryStringParamsCheck)) {
            searchParams.set("view", hasDefault.id);
            setSavedView(hasDefault.id);
            setSavedViewConfig(hasDefault);
        }

        // This isn't great, but the downside would be to do a lot more work to get back to a working state
        // I tried to add some useCallbacks where necessary, but it caused more bugs than are worth dealing with currently
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        // sorting configs
        if (radarConfigs) {
            // toSorted doesn't mutate original array
            const sortedRadarConfigs = [...radarConfigs].sort((a: any, b: any) => {
                // TODO: Need better types
                return Number(b.is_default) - Number(a.is_default) || a.config_name.localeCompare(b.config_name);
            });
            setSortedConfigs(sortedRadarConfigs);
        }
    }, [radarConfigs]);

    React.useEffect(() => {
        // setting the view
        if (radarConfigs) {
            const theSearchParams = new URLSearchParams(searchParams.toString());
            const searchParamsView = theSearchParams.get("view");
            if (searchParamsView !== String(savedViewConfig?.id)) {
                const foundConfig = radarConfigs.find((cf: any) => String(cf.id) === String(searchParamsView));
                if (foundConfig) {
                    setSavedView(foundConfig?.id ?? "");
                } else {
                    setSavedViewConfig(undefined);
                }
            } else {
                const selectedConfig: any = radarConfigs.find((cf: any) => String(cf.id) === String(view));
                setSavedViewConfig(selectedConfig);
            }

            if (deleteConfig && String(deleteConfig?.id) === view) {
                setIsSelected(true);
            }
        }
        // This isn't great, but the downside would be to do a lot more work to get back to a working state
        // I tried to add some useCallbacks where necessary, but it caused more bugs than are worth dealing with currently
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteConfig, radarConfigs, view]);

    const setView = (v: string) => {
        const updatedSearchParams = new URLSearchParams(searchParams.toString());
        updatedSearchParams.set("view", v);
        updatedSearchParams.delete("personId");
        setSearchParams(updatedSearchParams.toString());
        if (v === RadarConfigNameEnum.ALL) {
            // reset filters when All Events is current view
            clearFilters();
        }
        close("view");
    };

    const setSavedView = (v: number) => {
        const selectedSaved = radarConfigs && radarConfigs.find((config: any) => config?.id === v);

        if (selectedSaved) {
            const updatedSearchParams = new URLSearchParams(selectedSaved.query_string_params);
            updatedSearchParams.set("view", selectedSaved.id);
            setSearchParams(updatedSearchParams.toString());
            setSavedViewConfig(selectedSaved);
            close("view");
        }
    };

    return (
        <React.Fragment>
            <OverlayTrigger
                rootClose
                trigger="click"
                placement="bottom-start"
                defaultShow={false}
                overlay={
                    <Popover className="mw-100 hide-arrow">
                        <Popover.Body>
                            <div style={{ width: "350px" }}>
                                {presetConfigs.map((viewType) => (
                                    <Row className="p-3" key={viewType}>
                                        <Form.Check
                                            className="ms-4 mt-2"
                                            type="radio"
                                            label={viewToString(viewType || "")}
                                            value={viewType}
                                            id={viewType}
                                            checked={view === viewType}
                                            onChange={(e) => setView(e.target.value)}
                                        />
                                    </Row>
                                ))}
                                {sortedConfigs && sortedConfigs.length > 0 && (
                                    <React.Fragment>
                                        <hr />
                                        {sortedConfigs.map((config: any) => {
                                            return (
                                                <Row className="p-3" key={config.id}>
                                                    <div className="d-flex justify-content-between align-items-center p-0">
                                                        <Form.Check
                                                            className="ms-4 mt-2 text-break"
                                                            type="radio"
                                                            label={config.config_name}
                                                            value={config.id}
                                                            id={config.id}
                                                            checked={view === String(config.id)}
                                                            onChange={(e) => setSavedView(config.id)}
                                                        />
                                                        <div className="d-flex gap-4">
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={
                                                                    <Tooltip id="make-default-tooltip">
                                                                        {config.is_default
                                                                            ? "Remove default radar config."
                                                                            : "Make default radar config."}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <FontAwesomeIcon
                                                                    icon={config.is_default ? fasStar : faStar}
                                                                    className={"text-primary cursor-pointer"}
                                                                    onClick={() =>
                                                                        setRadarConfigIsDefault({
                                                                            id: config.id,
                                                                            // if false, make true & vice versa, assuming we will always send the opposite of what is 'present' before action occurs
                                                                            is_default: !config.is_default,
                                                                        })
                                                                    }
                                                                />
                                                            </OverlayTrigger>
                                                            <FontAwesomeIcon
                                                                icon={faTrash}
                                                                className="text-danger cursor-pointer"
                                                                onClick={() => setDeleteConfig(config)}
                                                            />
                                                        </div>
                                                    </div>
                                                </Row>
                                            );
                                        })}
                                    </React.Fragment>
                                )}
                            </div>
                        </Popover.Body>
                    </Popover>
                }
            >
                <Button
                    data-testid="radar-first-class-filter-button"
                    className="cursor-pointer mb-2 me-2 text-nowrap rounded sort-filter pendo-radar-first-class-filter-button"
                    variant="outline-primary"
                    size="sm"
                    onClick={() => close("view")}
                >
                    <FontAwesomeIcon icon={faFilterList} />{" "}
                    <span className="fs-5">
                        {/*truncate the name of the custom config to maintain page structure*/}
                        {savedViewConfig ? `${savedViewConfig?.config_name.slice(0, 100)}...` : viewToString(view)}
                    </span>
                </Button>
            </OverlayTrigger>
            <RadarDeleteConfigModal
                show={!!deleteConfig}
                close={() => setDeleteConfig(false)}
                config={deleteConfig}
                isSelected={isSelected}
                setView={setView}
            />
        </React.Fragment>
    );
};

export default RadarFirstClassFilter;
