import { Col, Row } from "react-bootstrap";

type OwnProps = {
    user: any;
};

const UserSettingsCard = ({ user }: OwnProps) => {
    return (
        <Row>
            <Col className="d-none d-lg-flex">
                <div className="d-flex flex-column">
                    <h3 className="text-primary">{user.agency_name}</h3>
                    <h1 className="text-primary fw-bold">{user.full_name}</h1>
                </div>
            </Col>
            <Col className="my-3 g-0 d-lg-none d-flex flex-column align-items-center">
                <h3 className="text-primary mt-3">{user.agency_name}</h3>
                <h1 className="text-primary fw-bold">{user.full_name}</h1>
            </Col>
        </Row>
    );
};

export default UserSettingsCard;
