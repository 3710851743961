// v1 api call
import { useGetCallQuery, useGetIncidentQuery } from "../../api/api";
import { elasticHitsExtraction } from "../../utils/elastic";
// v1 component
import { EventCardBody } from "./event/EventCard";
import { useMultiAgency } from "../../hooks/useMultiAgency";

const RadarEventCard = ({ event }: { event: any }) => {
    const { getAgencyUUID } = useMultiAgency();
    //check if call number is array from an incident or if we need to query for incident using the calls call number
    const callId = event?.call_number && Array.isArray(event?.call_number) ? event?.call_number[0] : event?.call_number;
    const { data: callData } = useGetCallQuery({ id: callId, agencyUUID: getAgencyUUID(event?.agency_id) }, { skip: !callId });
    const { data: incidentData } = useGetIncidentQuery(
        { id: callId, agencyUUID: getAgencyUUID(event?.agency_id) },
        { skip: !!event?.incident_number }
    );
    let selectedEvent = {
        ...event,
    };
    if (callData) {
        const hits = elasticHitsExtraction(callData);
        const hit = hits && hits.length && hits[0];
        if (hit) {
            // formatting call as needed for EventCardBody
            const newData = { ...hit, ...hit._source, source: hit._source };
            selectedEvent = { ...selectedEvent, ...newData };
        }
    }

    if (incidentData) {
        const hits = elasticHitsExtraction(incidentData);
        const hit = hits && hits.length && hits[0];
        if (hit) {
            const newData = { ...hit, ...hit._source, source: hit._source };
            selectedEvent = { ...selectedEvent, ...newData };
        }
    }

    return <EventCardBody event={selectedEvent} />;
};

export default RadarEventCard;
