import { RadarConfigNameEnum } from "../../types/types";
import { unique } from "../../utils/array";

export const viewToString = (v: string) => {
    return (
        {
            [RadarConfigNameEnum.CUSTOM]: "Custom",
            [RadarConfigNameEnum.ALL]: "All Events",
        }[v] || ""
    );
};

export const presetConfigs = [RadarConfigNameEnum.ALL, RadarConfigNameEnum.CUSTOM];

export const getRadarInitialLink = (radarConfigsData: any, defaultSubAgencyUUID?: string) => {
    // Set initial search params for Radar View
    const baseUrl = "/app/radar";
    const searchParams = new URLSearchParams();

    const hasDefault = radarConfigsData?.results?.find((item: any) => item.is_default);

    if (hasDefault) {
        // removes CUSTOM from query_string_params for ID to ensure proper default selecting UI
        const defaultSearchParams = new URLSearchParams(hasDefault.query_string_params);
        defaultSearchParams.set("view", hasDefault.id);

        // append agencyUUID to params if not yet present and is multiagency
        // defaultSubAgencyUUID will only possibly be set if current agency is multiagency
        // as uuid is derived from `useSingleAgencyFilter`
        if (!defaultSearchParams.get("agencyUUID") && !!defaultSubAgencyUUID) {
            defaultSearchParams.set("agencyUUID", defaultSubAgencyUUID);
        }
        return `${baseUrl}?${defaultSearchParams.toString()}`;
    }

    searchParams.set("dateRange", "4days");
    searchParams.set("view", RadarConfigNameEnum.ALL);

    // append agencyUUID to params if not yet present and is multiagency
    if (!!defaultSubAgencyUUID) {
        searchParams.set("agencyUUID", defaultSubAgencyUUID);
    }

    return `${baseUrl}?${searchParams}`;
};

export const collapseRows = (rows: any[]) => {
    //filter rows to just calls and add incidents as needed
    let collapsedCalls: any[] = rows?.filter((r) => r._index.includes("call"));
    rows &&
        Array.isArray(rows) &&
        rows.forEach((row) => {
            if (row?._index?.includes("incident") && row?.call_number?.length > 0) {
                let matchFound = false;
                //attempt to match each call connected to the incident
                row.call_number.forEach((r: string) => {
                    //Search for the first matching call to our incident call number and update with incident info
                    //theoretically could overwrite a call that has already been merged if 2 incidents share a call
                    //this is a rare case that we will address in a new radar build
                    let matchingIndex = collapsedCalls.findIndex((call) => call.call_number === r);
                    if (matchingIndex !== -1) {
                        matchFound = true;
                        //combine labels array without dupes
                        if (row.flags || collapsedCalls[matchingIndex].flags) {
                            row.flags = unique((row.flags || []).concat(collapsedCalls[matchingIndex].flags || []));
                        }
                        //build merged key field to avoid duplicates in the table
                        row.mergedKey = row._id + "-" + collapsedCalls[matchingIndex]._id;

                        //merge with incident data replacing call data
                        collapsedCalls[matchingIndex] = { ...collapsedCalls[matchingIndex], ...row };
                    }
                });
                //If no calls were found matching incident call_number array insert incident
                if (!matchFound) {
                    collapsedCalls.push(row);
                }
            } else if (row?._index.includes("incident") && (!row.call_number || row?.call_number?.length === 0)) {
                collapsedCalls.push(row);
            }
        });
    return collapsedCalls;
};
