import React from "react";
import { Container, Modal } from "react-bootstrap";
import ModalHeader from "../../components/ModalHeader";
import BeautifiedKeyValuePairDisplay from "../../utils/beautifiedkeyvaluepairdisplay/BeautifiedKeyValuePairDisplay";

const NarrativesModal = ({
    narrative,
    show,
    close,
    id,
    agency_shortname,
}: {
    narrative: any;
    show: boolean;
    close: any;
    id: string;
    agency_shortname: string;
}) => {
    return (
        <Modal show={show} onHide={close} size="xl" scrollable={true}>
            <ModalHeader title="Narrative" />
            <Modal.Body
                className={`${["co-acso1", "va-alpd1"].includes(agency_shortname) ? "fm-narratives-style-normalization" : ""} px-0`}
            >
                <Container fluid>
                    <React.Fragment key={id}>
                        <BeautifiedKeyValuePairDisplay value={narrative} showBreak={false} />
                    </React.Fragment>
                </Container>
            </Modal.Body>
        </Modal>
    );
};

export default NarrativesModal;
