import React from "react";
import { Button, Form } from "react-bootstrap";

declare let darkmode: any;

const LightDarkModeForm = () => {
    const currentValue = darkmode.getFromLocalStorage();

    const [currentSelection, setCurrentSelection] = React.useState<"system" | "dark" | "light">(currentValue ? currentValue : "system");

    const closeAndApplySettings = () => {
        if (currentSelection === "dark") {
            darkmode.dark();
        } else if (currentSelection === "light") {
            darkmode.light();
        } else {
            darkmode.system();
        }
    };

    return (
        <>
            <Form>
                <Form.Check
                    label="Light"
                    name="color-choice-light"
                    type="radio"
                    className="mb-3"
                    id="color-choice-light"
                    checked={currentSelection === "light"}
                    onChange={() => {
                        setCurrentSelection("light");
                    }}
                />
                <Form.Check
                    label="Dark"
                    name="color-choice-dark"
                    type="radio"
                    className="mb-3"
                    id="color-choice-dark"
                    checked={currentSelection === "dark"}
                    onChange={() => {
                        setCurrentSelection("dark");
                    }}
                />
                <Form.Check
                    label="System - Automatically switches between light and dark mode when your device does"
                    name="color-choice-system"
                    type="radio"
                    className="mb-3"
                    id="color-choice-system"
                    checked={currentSelection === "system"}
                    onChange={() => {
                        setCurrentSelection("system");
                    }}
                />

                <Button id="apply-color-scheme" onClick={closeAndApplySettings}>
                    Apply Settings
                </Button>
            </Form>
        </>
    );
};

export default LightDarkModeForm;
