import { Col, Container, Nav, Row } from "react-bootstrap";
import { useParams, useSearchParams } from "react-router-dom";
import PersonOverview from "./PersonOverview";
import { useSetPageTitle } from "../../hooks/useSetPageTitle";
import {
    useGetPersonAliasesQuery,
    useGetPersonIncidentsQuery,
    useGetPersonAssociatedPeopleQuery,
    useGetPersonPossibleConnectionsQuery,
    useGetPersonQuery,
} from "../../api/api";
import AssociatedPeopleView from "./AssociatedPeopleView";
import LoadingModal from "../../components/LoadingModal";
import { SortConfig, SortDirectionEnum } from "../../types/old_v1/types";
import { getQueryParams } from "../../utils/url";
import EventList from "../../components/entity/EventList";
import TimelineView from "../../components/entity/TimelineView";
import React from "react";
import { scrollToTop } from "../../utils/helpers";
import { TabEnum, TabEnumType } from "../../types/types";
import { useScrollToTopMultiDevice } from "../../hooks/useScrollToTopMultiDevice";
import FMSpinner from "../../components/Spinner";
import AssociatedIncidentsView from "./AssociatedIncidentsView";
import { useMultiAgency } from "../../hooks/useMultiAgency";

export const checkDesiredQueryParams = (searchParams: URLSearchParams) => {
    const queryParams = getQueryParams(searchParams);
    const queryParamCheck = Object.keys(queryParams).filter(
        (key: string | null) => queryParams[key as keyof typeof queryParams] !== null && key !== "query" && key !== "page"
    );
    return queryParamCheck.length >= 1;
};

const ConnectedPersonPage = () => {
    const { agencyUUID, personId } = useParams();
    const { getAgencyIdFromUUID } = useMultiAgency();
    const [searchParams, setSearchParams] = useSearchParams();
    const queryParams = getQueryParams(searchParams);

    useSetPageTitle(`ForceMetrics | Person ${personId}`);
    const listRef = React.useRef<any>(null);

    const { data: personResponse, isFetching: isFetchingPerson } = useGetPersonQuery(
        {
            personId: personId as string,
            agencyId: getAgencyIdFromUUID(agencyUUID),
        },
        { skip: !personId }
    );
    const person = personResponse?.result;

    const { data: associatedPeopleData, isFetching: isFetchingAssociatedPeople } = useGetPersonAssociatedPeopleQuery(
        {
            personId: personId as string,
            agencyId: getAgencyIdFromUUID(agencyUUID),
        },
        { skip: !personId }
    );

    const { data: incidentsData, isFetching: isFetchingIncidents } = useGetPersonIncidentsQuery(
        {
            personId: personId as string,
            agencyId: getAgencyIdFromUUID(agencyUUID),
        },
        { skip: !personId }
    );

    const birthdate = person?.birthdate;
    let formattedDob;
    if (birthdate) {
        formattedDob = birthdate.split("T")[0];
    }

    const { data: personAliasesData } = useGetPersonAliasesQuery(
        { personId: personId as string, agencyUUID, parentRecordId: person?.parent_record_id, formattedResults: true },
        { skip: !person?.parent_record_id || !agencyUUID }
    );

    const sortedAliases = personAliasesData
        ? [...personAliasesData.aliases].sort((a: any, b: any) => {
              const aLast = (a && a.source.last_name) || "";
              const aFirst = (a && a.source.first_name) || "";
              const aMiddle = (a && a.source.middle_name) || "";
              const bLast = (b && b.source.last_name) || "";
              const bFirst = (b && b.source.first_name) || "";
              const bMiddle = (b && b.source.middle_name) || "";
              return aLast.localeCompare(bLast) || aFirst.localeCompare(bFirst) || aMiddle.localeCompare(bMiddle);
          })
        : [];

    // Get possible connection data after receiving the person response
    const { data: personPossibleConnectionsData, isFetching: isFetchingPossibleConnections } = useGetPersonPossibleConnectionsQuery(
        {
            first_name: person?.first_name,
            last_name: person?.last_name,
            dob: formattedDob,
            formattedResults: true,
            ...queryParams,
            page_size: 10000,
            page: 1,
            agencyUUID,
        },
        { skip: !personResponse }
    );

    // Get array of incident numbers to find matching incidents and possible connections
    const incidentNumbers = Array.isArray(incidentsData?.results)
        ? incidentsData?.results?.map((incident: { incident_number: string | undefined }) => incident?.incident_number)
        : [];

    // If possible connection is in Incidents, don't add to possible connections
    let incidentFilteredConnections: any[] = Array.isArray(personPossibleConnectionsData?.person_mentions)
        ? personPossibleConnectionsData?.person_mentions?.map((mention: { index: any; id: any; source: { incident_number: any } }) => {
              if (!incidentNumbers.includes(mention?.source?.incident_number)) {
                  return mention;
              }
              return null;
          })
        : [];

    // ensure possible connection isn't null
    incidentFilteredConnections = incidentFilteredConnections.filter((connection) => connection !== null);
    const possibleConnectionsCount = incidentFilteredConnections?.length;
    const isPersonFiltersApplied = checkDesiredQueryParams(searchParams);

    // set current tab to url param if value in tab enum; else, default to overview tab
    const currentTab = Object.values(TabEnum).includes(searchParams.get("currentTab") as TabEnumType)
        ? (searchParams.get("currentTab") as TabEnumType)
        : TabEnum.INCIDENTS;

    const setCurrentTab = (value: TabEnumType) => {
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.set("currentTab", value);
        setSearchParams(newSearchParams);
    };

    const associatedPeople =
        associatedPeopleData?.aggregations?.associated_involved_people?.buckets?.map((person: any) => {
            return {
                ...person,
                associated_event_count: person?.mutual_incidents?.count || 0,
            };
        }) || [];

    // create dictionary of incident roles for each incident from unique roles per incident agg
    // and sort roles alphabetically
    const incidentRolesDictionary: { [key: string]: string[] } = {};
    incidentsData?.aggregations?.their_unique_roles_per_incident_number?.buckets?.forEach((bucket: { key: string; roles: string[] }) => {
        if (bucket.key && Array.isArray(bucket.roles)) {
            incidentRolesDictionary[bucket.key] = [...bucket.roles].sort();
        }
    });

    const pageResetDependencies = React.useMemo(
        () => ({ currentTab, searchParams, scrollToId: "mobilePersonNav" }),
        [currentTab, searchParams]
    );

    // Scroll to top of page on every new load of the event page
    React.useEffect(() => {
        scrollToTop();
    }, []);

    // Only scroll to top of page when currentTab changes and the screensize is larger or equal to mobile size
    useScrollToTopMultiDevice(currentTab, searchParams, "mobilePersonNav");

    return (
        <PersonPage
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            person={person}
            incidentsCount={incidentsData?.meta?.total_hits || 0}
            incidentFilteredConnections={incidentFilteredConnections}
            isPersonFiltersApplied={isPersonFiltersApplied}
            possibleConnectionsCount={possibleConnectionsCount}
            associatedPeopleCount={associatedPeopleData?.aggregations?.associated_involved_people?.doc_count || 0}
            isFetchingIncidents={isFetchingIncidents}
            isFetchingPerson={isFetchingPerson}
            // isFetchingPerson is first before isFetchingPossibleConnections
            isFetchingAssociatedPeople={isFetchingAssociatedPeople}
            isFetchingPossibleConnections={isFetchingPossibleConnections || isFetchingPerson}
            scrollToTop={scrollToTop}
            listRef={listRef}
            associatedPeople={associatedPeople}
            events={incidentsData?.results || []}
            pageReset={pageResetDependencies}
            aliases={sortedAliases}
            incidentRolesDictionary={incidentRolesDictionary}
        />
    );
};

const connectionsSortConfig: SortConfig = {
    score: {
        label: "Relevance",
        defaultSortDirection: SortDirectionEnum.NONE,
        sortString: false,
    },
    occurred_at: {
        label: "Date",
        defaultSortDirection: SortDirectionEnum.DESCENDING,
        sortString: true,
    },
};

const peopleSortConfig: SortConfig = {
    birthdate: {
        label: "DOB",
        defaultSortDirection: SortDirectionEnum.ASCENDING,
        sortString: true,
    },
    "last_name,first_name": {
        label: "Name",
        defaultSortDirection: SortDirectionEnum.ASCENDING,
        sortString: true,
    },
    latest_incident_occurred_at: {
        label: "Latest Event",
        defaultSortDirection: SortDirectionEnum.DESCENDING,
        sortString: true,
    },
    associated_event_count: {
        label: "Associated Events",
        defaultSortDirection: SortDirectionEnum.DESCENDING,
        sortString: true,
    },
};

const incidentSortConfig: SortConfig = {
    occurred_at: {
        label: "Date",
        defaultSortDirection: SortDirectionEnum.ASCENDING,
        sortString: true,
    },
};

type OwnProps = {
    currentTab: TabEnumType;
    setCurrentTab: (value: TabEnumType) => void;
    person: any;
    incidentFilteredConnections: any[];
    isPersonFiltersApplied?: boolean;
    possibleConnectionsCount: number;
    incidentsCount: number;
    associatedPeopleCount: number;
    isFetchingPerson: boolean;
    isFetchingIncidents: boolean;
    isFetchingAssociatedPeople: boolean;
    isFetchingPossibleConnections: boolean;
    scrollToTop?: any;
    listRef?: any;
    associatedPeople: any;
    events: any;
    pageReset: any;
    aliases: any[];
    incidentRolesDictionary: { [key: string]: string[] };
};
const PersonPage = ({
    currentTab,
    setCurrentTab,
    person,
    incidentsCount,
    incidentFilteredConnections,
    isPersonFiltersApplied,
    possibleConnectionsCount,
    associatedPeopleCount,
    isFetchingPerson,
    isFetchingIncidents,
    isFetchingAssociatedPeople,
    isFetchingPossibleConnections,
    scrollToTop,
    listRef,
    associatedPeople,
    events,
    pageReset,
    aliases,
    incidentRolesDictionary = {},
}: OwnProps) => {
    // add list of roles to each associated incident
    const incidentsWithRole = React.useMemo(
        () =>
            events?.map((incident: any) => ({
                ...incident,
                id: incident.es_source_doc_id,
                roles: incidentRolesDictionary?.[incident.incident_number] || [],
            })),
        [events, incidentRolesDictionary]
    );

    return (
        <Container className="my-4" fluid="xl">
            <Row>
                <Col className="mb-4" xs={12} md={3}>
                    <PersonOverview isFetchingPerson={isFetchingPerson} person={person} aliases={aliases} eventData={incidentsWithRole} />
                </Col>
                <Col xs={12} md={9} className="px-0 px-md-4" ref={listRef}>
                    <Nav className="d-none d-md-flex border-bottom-0" variant="tabs" activeKey={currentTab}>
                        <DesktopPersonDetailNavItems
                            incidentsCount={incidentsCount}
                            isFetchingIncidents={isFetchingIncidents}
                            isPersonFiltersApplied={isPersonFiltersApplied}
                            possibleConnectionsCount={possibleConnectionsCount}
                            isFetchingPossibleConnections={isFetchingPossibleConnections}
                            setCurrentTab={setCurrentTab}
                            associatedPeopleCount={associatedPeopleCount}
                        />
                    </Nav>
                    <Nav id="mobilePersonNav" variant="pills" className="d-md-none gap-3 d-flex flex-column text-center p-4">
                        <MobilePersonDetailNavItems
                            incidentsCount={incidentsCount}
                            isFetchingIncidents={isFetchingIncidents}
                            isPersonFiltersApplied={isPersonFiltersApplied}
                            possibleConnectionsCount={possibleConnectionsCount}
                            isFetchingPossibleConnections={isFetchingPossibleConnections}
                            setCurrentTab={setCurrentTab}
                            currentTab={currentTab}
                            associatedPeopleCount={associatedPeopleCount}
                        />
                    </Nav>
                    <div className="min-vh-100-md h-auto fm-bg-color border-0 border-md rounded-end rounded-bottom p-4 d-flex flex-column flex-grow-1 mb-5">
                        {currentTab === TabEnum.INCIDENTS && (
                            <AssociatedIncidentsView
                                incidentsData={incidentsWithRole}
                                isLoading={isFetchingIncidents}
                                sortConfig={incidentSortConfig}
                                scrollToTop={scrollToTop}
                                pageReset={pageReset}
                            />
                        )}
                        {currentTab === TabEnum.CONNECTIONS && (
                            // Possible Connections View
                            <EventList
                                eventData={incidentFilteredConnections}
                                sortConfig={connectionsSortConfig}
                                defaultSortField="score"
                                defaultSortDirection={SortDirectionEnum.NONE}
                                isLoading={isFetchingPossibleConnections}
                                scrollToTop={scrollToTop}
                                pageReset={pageReset}
                                showInvolvedPeople={false}
                                // hide agencies filter since possible connections are agency specific
                                hideAgenciesFilter={true}
                            />
                        )}
                        {currentTab === TabEnum.ASSOCIATED_PEOPLE && (
                            <AssociatedPeopleView
                                currentPerson={person}
                                isLoading={isFetchingAssociatedPeople}
                                associatedPeople={associatedPeople}
                                sortConfig={peopleSortConfig}
                                defaultSortField="associated_event_count"
                                defaultSortDirection={peopleSortConfig["associated_event_count"].defaultSortDirection}
                                scrollToTop={scrollToTop}
                                pageReset={pageReset}
                                agencyId={person?.agency_id}
                                rolesDictionary={incidentRolesDictionary}
                            />
                        )}
                        {currentTab === TabEnum.TIMELINE && <TimelineView isLoading={isFetchingIncidents} incidents={incidentsWithRole} />}
                    </div>
                </Col>
            </Row>
            <LoadingModal show={isFetchingIncidents || isFetchingPerson} />
        </Container>
    );
};

type TPersonDetailNavItems = {
    incidentsCount: number;
    isFetchingIncidents: boolean;
    possibleConnectionsCount: number;
    isPersonFiltersApplied?: boolean;
    isFetchingPossibleConnections: boolean;
    setCurrentTab: (value: TabEnumType) => void;
    currentTab?: TabEnumType;
    associatedPeopleCount: number;
};

const DesktopPersonDetailNavItems = ({
    incidentsCount,
    isFetchingIncidents,
    possibleConnectionsCount,
    isPersonFiltersApplied,
    isFetchingPossibleConnections,
    setCurrentTab,
    associatedPeopleCount,
}: TPersonDetailNavItems) => (
    <>
        <Nav.Item>
            <Nav.Link
                data-testid="events-tab-button"
                className="pendo_events_tab_nav_button"
                onClick={() => setCurrentTab(TabEnum.INCIDENTS)}
                eventKey={TabEnum.INCIDENTS}
            >
                Events {isFetchingIncidents ? "" : incidentsCount > 0 && `- ${incidentsCount}`}
            </Nav.Link>
        </Nav.Item>
        <Nav.Item className={`${associatedPeopleCount < 1 ? "cursor-not-allowed" : ""}`}>
            <Nav.Link
                data-testid="associated-people-tab-button"
                className="pendo_associated_people_tab_nav_button"
                disabled={associatedPeopleCount < 1}
                onClick={() => setCurrentTab(TabEnum.ASSOCIATED_PEOPLE)}
                eventKey={TabEnum.ASSOCIATED_PEOPLE}
            >
                Associated People {isFetchingIncidents ? "" : associatedPeopleCount > 0 && `- ${associatedPeopleCount}`}
            </Nav.Link>
        </Nav.Item>
        <Nav.Item className={`${possibleConnectionsCount < 1 && !isPersonFiltersApplied ? "cursor-not-allowed" : ""}`}>
            <Nav.Link
                data-testid="possible-connections-tab-button"
                className="pendo_possible_connections_tab_nav_button"
                disabled={possibleConnectionsCount < 1 && !isPersonFiltersApplied}
                onClick={() => setCurrentTab(TabEnum.CONNECTIONS)}
                eventKey={TabEnum.CONNECTIONS}
            >
                Possible Connections{" "}
                {isFetchingPossibleConnections ? (
                    <span className="ps-2">
                        <FMSpinner small />
                    </span>
                ) : (
                    possibleConnectionsCount > 0 && `- ${possibleConnectionsCount}`
                )}
            </Nav.Link>
        </Nav.Item>
        <Nav.Item className={`${incidentsCount < 1 ? "cursor-not-allowed" : ""}`}>
            <Nav.Link
                data-testid="timeline-tab-button"
                className="pendo_timeline_tab_nav_button"
                disabled={incidentsCount === 0}
                onClick={() => setCurrentTab(TabEnum.TIMELINE)}
                eventKey={TabEnum.TIMELINE}
            >
                Timeline
            </Nav.Link>
        </Nav.Item>
    </>
);

const MobilePersonDetailNavItems = ({
    incidentsCount,
    isFetchingIncidents,
    possibleConnectionsCount,
    isPersonFiltersApplied,
    isFetchingPossibleConnections,
    setCurrentTab,
    currentTab,
    associatedPeopleCount,
}: TPersonDetailNavItems) => (
    <>
        <Nav.Item>
            <Nav.Link
                className={`${currentTab === TabEnum.INCIDENTS ? "active" : ""} rounded border border-primary pendo_events_tab_nav_button`}
                onClick={() => setCurrentTab(TabEnum.INCIDENTS)}
                eventKey={TabEnum.INCIDENTS}
            >
                Events {isFetchingIncidents ? "" : incidentsCount > 0 && `- ${incidentsCount}`}
            </Nav.Link>
        </Nav.Item>
        <Nav.Item>
            <Nav.Link
                className={`${
                    currentTab === TabEnum.ASSOCIATED_PEOPLE ? "active" : ""
                } rounded border border-primary pendo_associated_people_tab_nav_button`}
                onClick={() => setCurrentTab(TabEnum.ASSOCIATED_PEOPLE)}
                eventKey={TabEnum.ASSOCIATED_PEOPLE}
                disabled={associatedPeopleCount < 1}
            >
                Associated People {isFetchingIncidents ? "" : associatedPeopleCount > 0 && `- ${associatedPeopleCount}`}
            </Nav.Link>
        </Nav.Item>
        <Nav.Item>
            <Nav.Link
                className={`${
                    currentTab === TabEnum.CONNECTIONS ? "active" : ""
                } rounded border border-primary pendo_possible_connections_tab_nav_button`}
                onClick={() => setCurrentTab(TabEnum.CONNECTIONS)}
                eventKey={TabEnum.CONNECTIONS}
                disabled={possibleConnectionsCount < 1 && !isPersonFiltersApplied}
            >
                Possible Connections {isFetchingPossibleConnections ? "" : possibleConnectionsCount > 0 && `- ${possibleConnectionsCount}`}
            </Nav.Link>
        </Nav.Item>
        <Nav.Item>
            <Nav.Link
                disabled={incidentsCount === 0}
                className={`${currentTab === TabEnum.TIMELINE ? "active" : ""} rounded border border-primary pendo_timeline_tab_nav_button`}
                onClick={() => setCurrentTab(TabEnum.TIMELINE)}
                eventKey={TabEnum.TIMELINE}
            >
                Timeline
            </Nav.Link>
        </Nav.Item>
    </>
);

export default ConnectedPersonPage;
