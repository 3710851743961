import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Col, Container, Row, Stack } from "react-bootstrap";
import { useSetPageTitle } from "../../hooks/useSetPageTitle";
import { getRangeLabel } from "../radar/filters/Filters";
import { Link, useSearchParams } from "react-router-dom";
import { useGetOfficerCountQuery, useGetOfficerCountsByBeatsQuery, useGetTotalEventsCountQuery } from "../../api/api";
import { formatPercent } from "../../utils/number";
import TopLabels from "../insightsdashboard/TopLabels";
import OffensesHistogram from "../insightsdashboard/OffensesHistogram";
import InsightsMap from "./officerinsightsdashboard/InsightsMap";
import IncidentsByDay from "./officerinsightsdashboard/IncidentsByDay";
import IncidentsByHour from "./officerinsightsdashboard/IncidentsByHour";
import IncidentsByTimeBlock from "./officerinsightsdashboard/IncidentsByTimeBlock";
import IncidentsOverTime from "./officerinsightsdashboard/IncidentsOverTime";
import { toUpperCase } from "../../utils/string";
import { faUpRightFromSquare } from "@fortawesome/pro-light-svg-icons";
import { faFilter, faUserPolice } from "@fortawesome/pro-regular-svg-icons";
import FilterBar from "../../components/modalFilters/FilterBar";
import FiltersModal from "../../components/modalFilters/FiltersModal";
import useAgencySettings from "../../hooks/useAgencySettings";
import useEncoderDecoder from "../../hooks/useEncoderDecoder";
import { useMultiAgency } from "../../hooks/useMultiAgency";
import useSingleAgencyFilter from "../../hooks/useSingleAgencyFilter";
import InsightsSpinner from "../insightsdashboard/insightsspinner/InsightsSpinner";
import { useFilters } from "../../hooks/useFilters";

const ConnectedOfficerInsightsDashboard = () => {
    const [searchParams] = useSearchParams();
    const { queryParams } = useSingleAgencyFilter();
    const { decodedData: officer } = useEncoderDecoder({ encodedData: searchParams.get("officer") || "" });
    queryParams.indexType = ["incidents"];
    const { beatsIsFetching, filterPayload, selectedBeats } = useFilters({ isSingleAgencyMode: true, includeGeometry: true });

    const beatsCountResponse = useGetOfficerCountsByBeatsQuery({ ...queryParams, filters: filterPayload }, { skip: beatsIsFetching });

    const filterApplied = !!queryParams.officer || !!selectedBeats?.length;

    const { data: totalEventsData } = useGetTotalEventsCountQuery({
        query: queryParams.query,
        dateRange: queryParams.dateRange,
        filters: {},
    });

    const {
        data: officerCountResponse,
        isFetching,
        isLoading,
    } = useGetOfficerCountQuery({ ...queryParams, filters: filterPayload }, { skip: beatsIsFetching });

    let filteredIncidentCount = null;
    let labelBuckets = null;
    let offensesBuckets = null;

    if (!isLoading && !beatsIsFetching) {
        filteredIncidentCount = officerCountResponse?.data?.total_incidents;
        labelBuckets = officerCountResponse?.data?.label_buckets;
        offensesBuckets = officerCountResponse?.data?.offenses_buckets;
    }

    return (
        <OfficerInsightsDashboard
            isFetching={isFetching}
            totalIncidentCount={totalEventsData?.aggregations?.total_event_count?.value}
            filteredIncidentCount={filteredIncidentCount}
            labelBuckets={labelBuckets}
            offensesBuckets={offensesBuckets}
            selectedOfficer={officer}
            filterApplied={filterApplied}
            beatsCountResponse={beatsCountResponse}
        />
    );
};

type OwnProps = {
    isFetching: boolean;
    totalIncidentCount: number;
    labelBuckets: any;
    offensesBuckets: any;
    selectedOfficer: string | null;
    filteredIncidentCount: number;
    filterApplied: boolean;
    beatsCountResponse: any;
};
const OfficerInsightsDashboard = ({
    isFetching,
    totalIncidentCount,
    filteredIncidentCount,
    labelBuckets,
    offensesBuckets,
    selectedOfficer,
    filterApplied,
    beatsCountResponse,
}: OwnProps) => {
    useSetPageTitle("ForceMetrics | Officer Insights Dashboard");
    const { selectedAgency } = useSingleAgencyFilter();
    const { isMultiAgency } = useMultiAgency();
    const [searchParams] = useSearchParams();
    const { incidentTextPlural } = useAgencySettings();
    const dateRange = searchParams.get("dateRange");
    const startDate = searchParams.get("startDate");
    const endDate = searchParams.get("endDate");

    const [show, setShow] = React.useState(false);

    const activeOfficerPercent =
        !isNaN(filteredIncidentCount / totalIncidentCount) && totalIncidentCount !== 0 ? filteredIncidentCount / totalIncidentCount : 0;

    const constructMainSearchLink = () => {
        const mainSearchParams = new URLSearchParams(searchParams);
        mainSearchParams.set("indexType", "incidents");

        if (selectedAgency) {
            mainSearchParams.set("agencies", selectedAgency?.fm_uuid);
        }
        return `/app/search?${mainSearchParams}`;
    };

    return (
        <Container style={{ marginTop: "1em" }} className="officer-insights-dashboard-container" fluid>
            <Row className="mb-3">
                <Col sm={12}>
                    <Stack direction="horizontal" className="justify-content-between">
                        <h2 className="d-flex mb-3 p-0 align-items-center">
                            <FontAwesomeIcon icon={faUserPolice} />
                            <span className="">&nbsp;Officer Dashboard</span>
                        </h2>
                        <h1>
                            {dateRange !== "custom" && "Last "}
                            {toUpperCase(getRangeLabel(dateRange, startDate, endDate))} Overview
                        </h1>
                        <div className="align-self-center d-flex gap-4 mb-4">
                            <div>
                                <Button
                                    className="pendo_filters_modal_open_button cursor-pointer container-md justify-content-center d-inline-flex align-items-center gap-2 text-nowrap rounded sort-filter"
                                    onClick={() => setShow(true)}
                                >
                                    <FontAwesomeIcon icon={faFilter} /> Filters
                                </Button>
                            </div>
                            <FilterBar
                                showModal={!show ? () => setShow(true) : null}
                                tab={"officer"}
                                showSingleAgencyFilter={isMultiAgency}
                                isSingleAgencyMode
                                includeBeatGeometry
                            />
                            <FiltersModal
                                show={show}
                                handleClose={() => setShow(false)}
                                selectedTab={"officer"}
                                resultsText={incidentTextPlural}
                                defaultFilterValues={{ dateRange: "30days" }}
                                defaultDateRange="30days"
                                totalSearchResults={totalIncidentCount}
                                showSingleAgencyFilter={isMultiAgency}
                                isSingleAgencyMode
                                includeBeatGeometry
                            />
                        </div>
                    </Stack>
                    <Row>
                        <Col xs={4}>
                            <Card>
                                <Card.Header>
                                    <Card.Title className="m-0" as="h3">
                                        Total {incidentTextPlural}
                                    </Card.Title>
                                </Card.Header>
                                <Card.Body className="text-center display-6 fw-bold p-4">{totalIncidentCount || 0}</Card.Body>
                            </Card>
                        </Col>
                        {filterApplied && (
                            <Col xs={4}>
                                <Card>
                                    <Card.Header>
                                        <Card.Title className="m-0" as="h3">
                                            Filtered {selectedOfficer} {incidentTextPlural}
                                        </Card.Title>
                                    </Card.Header>
                                    <Card.Body className={`text-center text-primary ${!isFetching ? "p-4" : ""}`}>
                                        {isFetching ? (
                                            <InsightsSpinner />
                                        ) : (
                                            <Link to={constructMainSearchLink()} target="_blank" className="d-flex justify-content-center">
                                                <span className="display-6 fw-bold me-1">{filteredIncidentCount || 0}</span>
                                                <FontAwesomeIcon icon={faUpRightFromSquare} size="1x" />
                                            </Link>
                                        )}
                                    </Card.Body>
                                </Card>
                            </Col>
                        )}
                        {filterApplied && (
                            <Col xs={4}>
                                <Card>
                                    <Card.Header>
                                        <Card.Title className="m-0" as="h3">
                                            Percent of {incidentTextPlural}
                                        </Card.Title>
                                    </Card.Header>
                                    <Card.Body className={`text-center ${!isFetching ? "fw-bold display-6 p-4" : ""}`}>
                                        {isFetching ? <InsightsSpinner /> : formatPercent(activeOfficerPercent)}
                                    </Card.Body>
                                </Card>
                            </Col>
                        )}
                    </Row>
                    <Row className="mt-3">
                        <Col lg={6}>
                            <InsightsMap beatsCountResponse={beatsCountResponse} />
                        </Col>
                        <Col lg={6}>
                            <OffensesHistogram offensesBuckets={offensesBuckets} isFetching={isFetching} isClickable={true} />
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col lg={4}>
                            <TopLabels tagBuckets={labelBuckets} isFetching={isFetching} isClickable={true} indexType="incidents" />
                        </Col>
                        <Col lg={8}>
                            <IncidentsOverTime />
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <hr />
                            <h1>Last Week</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6}>
                            <IncidentsByTimeBlock />
                        </Col>
                        <Col sm={6}>
                            <IncidentsByHour />
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <hr />
                            <h1>Last Year</h1>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col sm={12}>
                            <IncidentsByDay />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};

export default ConnectedOfficerInsightsDashboard;
