import React from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useSetPageTitle } from "../../hooks/useSetPageTitle";
import { Button, Card, Col, Container, Row, Stack } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faPhoneOffice } from "@fortawesome/pro-regular-svg-icons";
import { faUpRightFromSquare } from "@fortawesome/pro-light-svg-icons";
import { toUpperCase } from "../../utils/string";
import FilterBar, { getRangeLabel } from "../../components/modalFilters/FilterBar";
import { useGetDispatcherCountQuery, useGetDispatcherCountsByBeatsQuery, useGetTotalEventsCountQuery } from "../../api/api";
import { formatPercent } from "../../utils/number";
import InsightsMap from "./dispatcherinsightsdashboard/InsightsMap";
import TopLabels from "../insightsdashboard/TopLabels";
import CallsByHour from "./dispatcherinsightsdashboard/CallsByHour";
import CallsByDay from "./dispatcherinsightsdashboard/CallsByDay";
import CallsByTimeBlock from "./dispatcherinsightsdashboard/CallsByTimeBlock";
import CallsOverTime from "./dispatcherinsightsdashboard/CallsOverTime";
import CallHistogram from "./dispatcherinsightsdashboard/CallHistogram";
import FiltersModal from "../../components/modalFilters/FiltersModal";
import useAgencySettings from "../../hooks/useAgencySettings";
import useEncoderDecoder from "../../hooks/useEncoderDecoder";
import useSingleAgencyFilter from "../../hooks/useSingleAgencyFilter";
import { useMultiAgency } from "../../hooks/useMultiAgency";
import InsightsSpinner from "../insightsdashboard/insightsspinner/InsightsSpinner";
import { useFilters } from "../../hooks/useFilters";

const ConnectedDispatcherInsightsDashboard = () => {
    const [searchParams] = useSearchParams();
    const { queryParams } = useSingleAgencyFilter();
    const { decodedData: dispatcher } = useEncoderDecoder({ encodedData: searchParams.get("dispatcher") || "" });
    queryParams.indexType = ["calls"];
    const { beatsIsFetching, filterPayload, selectedBeats } = useFilters({ isSingleAgencyMode: true, includeGeometry: true });

    const beatsCountResponse = useGetDispatcherCountsByBeatsQuery({ ...queryParams, filters: filterPayload }, { skip: beatsIsFetching });

    const filterApplied = !!queryParams.dispatcher || !!selectedBeats?.length;

    const { data: totalEventsData } = useGetTotalEventsCountQuery({
        query: queryParams.query,
        dateRange: queryParams.dateRange,
        filters: {},
    });
    const {
        data: dispatcherCountResponse,
        isFetching,
        isLoading,
    } = useGetDispatcherCountQuery({ ...queryParams, filters: filterPayload }, { skip: beatsIsFetching });

    let filteredCallCount = null;
    let labelBuckets = null;
    let callTypeBuckets = null;

    if (!isLoading && !beatsIsFetching) {
        filteredCallCount = dispatcherCountResponse?.data?.total_calls;
        labelBuckets = dispatcherCountResponse?.data?.label_buckets;
        callTypeBuckets = dispatcherCountResponse?.data?.call_type_buckets;
    }

    return (
        <DispatcherInsightsDashboard
            isFetching={isFetching}
            totalCallCount={totalEventsData?.aggregations?.total_event_count?.value}
            filteredCallCount={filteredCallCount}
            labelBuckets={labelBuckets}
            callTypeBuckets={callTypeBuckets}
            selectedDispatcher={dispatcher}
            filterApplied={filterApplied}
            beatsCountResponse={beatsCountResponse}
        />
    );
};

type OwnProps = {
    isFetching: boolean;
    totalCallCount: number;
    filteredCallCount: number;
    labelBuckets: any;
    callTypeBuckets: any;
    selectedDispatcher: string | null;
    filterApplied: boolean;
    beatsCountResponse: any;
};

const DispatcherInsightsDashboard = ({
    isFetching,
    totalCallCount,
    filteredCallCount,
    labelBuckets,
    callTypeBuckets,
    selectedDispatcher,
    filterApplied,
    beatsCountResponse,
}: OwnProps) => {
    useSetPageTitle("ForceMetrics | Dispatcher Insights Dashboard");
    const { isMultiAgency } = useMultiAgency();
    const { selectedAgency } = useSingleAgencyFilter();
    const [searchParams] = useSearchParams();
    const { callTextPlural } = useAgencySettings();
    const dateRange = searchParams.get("dateRange");
    const startDate = searchParams.get("startDate");
    const endDate = searchParams.get("endDate");

    const [show, setShow] = React.useState(false);

    const activeDispatcherPercent =
        !isNaN(filteredCallCount / totalCallCount) && totalCallCount !== 0 ? filteredCallCount / totalCallCount : 0;

    const constructMainSearchLink = () => {
        const mainSearchParams = new URLSearchParams(searchParams);
        mainSearchParams.set("indexType", "calls");

        if (selectedAgency) {
            mainSearchParams.set("agencies", selectedAgency?.fm_uuid);
        }
        return `/app/search?${mainSearchParams}`;
    };

    return (
        <Container style={{ marginTop: "1em" }} className="dispatcher-insights-dashboard-container" fluid>
            <Row className="mb-3">
                <Col sm={12}>
                    <Stack direction="horizontal" className="justify-content-between">
                        <h2 className="d-flex mb-3 p-0 align-items-center">
                            <FontAwesomeIcon icon={faPhoneOffice} />
                            <span className="">&nbsp;Dispatcher Dashboard</span>
                        </h2>
                        <h1>
                            {dateRange !== "custom" && "Last "}
                            {toUpperCase(getRangeLabel(dateRange, startDate, endDate))} Overview
                        </h1>
                        <div className="align-self-center d-flex gap-4 mb-4">
                            <div>
                                <Button
                                    className="pendo_filters_modal_open_button cursor-pointer container-md justify-content-center d-inline-flex align-items-center gap-2 text-nowrap rounded sort-filter"
                                    onClick={() => setShow(true)}
                                >
                                    <FontAwesomeIcon icon={faFilter} /> Filters
                                </Button>
                            </div>
                            <FilterBar
                                showModal={!show ? () => setShow(true) : null}
                                tab={"dispatcher"}
                                showSingleAgencyFilter={isMultiAgency}
                                isSingleAgencyMode
                                includeBeatGeometry
                            />
                            <FiltersModal
                                show={show}
                                handleClose={() => setShow(false)}
                                selectedTab={"dispatcher"}
                                resultsText={callTextPlural}
                                defaultFilterValues={{ dateRange: "30days" }}
                                defaultDateRange="30days"
                                totalSearchResults={totalCallCount}
                                showSingleAgencyFilter={isMultiAgency}
                                isSingleAgencyMode
                                includeBeatGeometry
                            />
                        </div>
                    </Stack>
                    <Row>
                        <Col xs={4}>
                            <Card>
                                <Card.Header>
                                    <Card.Title className="m-0" as="h3">
                                        Total {callTextPlural}
                                    </Card.Title>
                                </Card.Header>
                                <Card.Body className="text-center display-6 fw-bold p-4">{totalCallCount || 0}</Card.Body>
                            </Card>
                        </Col>
                        {filterApplied && (
                            <Col xs={4}>
                                <Card>
                                    <Card.Header>
                                        <Card.Title className="m-0" as="h3">
                                            Filtered {selectedDispatcher} {callTextPlural}
                                        </Card.Title>
                                    </Card.Header>
                                    <Card.Body className={`text-center text-primary ${!isFetching ? "p-4" : ""}`}>
                                        {isFetching ? (
                                            <InsightsSpinner />
                                        ) : (
                                            <Link to={constructMainSearchLink()} target="_blank" className="d-flex justify-content-center">
                                                <span className="display-6 fw-bold me-1">{filteredCallCount || 0}</span>
                                                <FontAwesomeIcon icon={faUpRightFromSquare} size="1x" />
                                            </Link>
                                        )}
                                    </Card.Body>
                                </Card>
                            </Col>
                        )}
                        {filterApplied && (
                            <Col xs={4}>
                                <Card>
                                    <Card.Header>
                                        <Card.Title className="m-0" as="h3">
                                            Percent of {callTextPlural}
                                        </Card.Title>
                                    </Card.Header>
                                    <Card.Body className={`text-center  ${!isFetching ? "fw-bold display-6 p-4" : ""}`}>
                                        {isFetching ? <InsightsSpinner /> : formatPercent(activeDispatcherPercent)}
                                    </Card.Body>
                                </Card>
                            </Col>
                        )}
                    </Row>
                    <Row className="mt-3">
                        <Col lg={6}>
                            <InsightsMap beatsCountResponse={beatsCountResponse} />
                        </Col>
                        <Col lg={6}>
                            <CallHistogram callBuckets={callTypeBuckets} isFetching={isFetching} />
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col lg={4}>
                            <TopLabels tagBuckets={labelBuckets} isFetching={isFetching} isClickable={true} indexType="calls" />
                        </Col>
                        <Col lg={8}>
                            <CallsOverTime />
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <hr />
                            <h1>Last Week</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6}>
                            <CallsByTimeBlock />
                        </Col>
                        <Col sm={6}>
                            <CallsByHour />
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <hr />
                            <h1>Last Year</h1>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col sm={12}>
                            <CallsByDay />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};

export default ConnectedDispatcherInsightsDashboard;
