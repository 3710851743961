import { useNavigate } from "react-router-dom";
import usePermissions from "./usePermissions";

const useNextAwarePathname = (): {
    canPerformNextFESearch: boolean;
    landingPath: "/search" | "/app";
    mainSearchPath: "/search/results/events" | "/app/search";
    peopleSearchPath: "/search/results/people" | "/app/search";
} => {
    const { hasPermission } = usePermissions();
    const canPerformNextFESearch = hasPermission("use_nextfe_search");

    const landingPath = canPerformNextFESearch ? "/search" : "/app";
    const mainSearchPath = canPerformNextFESearch ? "/search/results/events" : "/app/search";
    const peopleSearchPath = canPerformNextFESearch ? "/search/results/people" : "/app/search";

    return {
        canPerformNextFESearch,
        landingPath,
        mainSearchPath,
        peopleSearchPath,
    };
};

export const useNextAwareNavigate = () => {
    const { canPerformNextFESearch } = useNextAwarePathname();
    const navigate = useNavigate();

    const nextAwareNavigate = (url: string) => {
        return canPerformNextFESearch ? window.location.assign(url) : navigate(url);
    };

    return {
        nextAwareNavigate
    }
}

export default useNextAwarePathname;
